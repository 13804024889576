import React from "react";
import PropTypes from "prop-types";
import privacy_policy from "../../assets/images/pdf/privacy_policy.pdf";

class TosModal extends React.Component {
  render () {
    const { open, toggle } = this.props;
    return (
      <>
        {open && (
          <div id="tos-modal">
            <div className="modal-bg" onClick={toggle}></div>
            <div className="modal-wrapper">
              <div className="modal-header">
                <h2>{I18n.t('global.footer.links.terms_of_service')}</h2>
                <div className="exit-modal" onClick={toggle}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
              <div className="modal-content">
                <p>{I18n.t('global.terms.subtitle_1')}</p>
                <p>{I18n.t('global.terms.welcome')}</p>
                <p>
                  {I18n.t('global.terms.paragraph_1.text_1')}
                  <b>{I18n.t('global.terms.paragraph_1.bold_1')}</b>
                  {I18n.t('global.terms.paragraph_1.text_2')}
                  <b>{I18n.t('global.terms.paragraph_1.bold_2')}</b>
                  {I18n.t('global.terms.paragraph_1.text_3')}
                  <b>{I18n.t('global.terms.paragraph_1.bold_3')}</b>
                  {I18n.t('global.terms.paragraph_1.text_4')}
                </p>
                <p>
                  {I18n.t('global.terms.paragraph_2.text_1')}
                  {/* TODO <%= link_to "https://smartcontract.hr/hr/kontakt", ("https://smartcontract.hr/hr/kontakt"))} */}
                </p>
                <p>{I18n.t('global.terms.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.paragraph_4.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.paragraph_4.list.item_1')}</li>
                </ul>
                <p>{I18n.t('global.terms.paragraph_5.text_1')}</p>
                <p>{I18n.t('global.terms.paragraph_6.text_1')}</p>
                <p>{I18n.t('global.terms.paragraph_7.text_1')}</p>
                <p>{I18n.t('global.terms.paragraph_8.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.paragraph_8.list.item_1')}</li>
                  <li>{I18n.t('global.terms.paragraph_8.list.item_2')}</li>
                  <li>{I18n.t('global.terms.paragraph_8.list.item_3')}</li>
                  <li>{I18n.t('global.terms.paragraph_8.list.item_4')}</li>
                </ul>
                <p>{I18n.t('global.terms.paragraph_9.text_1')}</p>

                <h2>{I18n.t('global.terms.subtitle_2')}</h2>

                <h4>{I18n.t('global.terms.section_1.title')}</h4>
                <h4>{I18n.t('global.terms.section_2.title')}</h4>
                <h4>{I18n.t('global.terms.section_3.title')}</h4>
                <h4>{I18n.t('global.terms.section_4.title')}</h4>
                <h4>{I18n.t('global.terms.section_5.title')}</h4>
                <h4>{I18n.t('global.terms.section_6.title')}</h4>
                <h4>{I18n.t('global.terms.section_7.title')}</h4>
                <h4>{I18n.t('global.terms.section_8.title')}</h4>
                <h4>{I18n.t('global.terms.section_9.title')}</h4>
                <h4>{I18n.t('global.terms.section_10.title')}</h4>
                <h4>{I18n.t('global.terms.section_11.title')}</h4>
                <h4>{I18n.t('global.terms.section_12.title')}</h4>
                <h4>{I18n.t('global.terms.section_13.title')}</h4>
                <h4>{I18n.t('global.terms.section_14.title')}</h4>
                <h4>{I18n.t('global.terms.section_15.title')}</h4>
                <h4>{I18n.t('global.terms.section_16.title')}</h4>
                <h4>{I18n.t('global.terms.section_17.title')}</h4>
                <h4>{I18n.t('global.terms.section_18.title')}</h4>
                <h4>{I18n.t('global.terms.section_19.title')}</h4>
                <h4>{I18n.t('global.terms.section_20.title')}</h4>
                <h4>{I18n.t('global.terms.section_21.title')}</h4>

                <h3>{I18n.t('global.terms.section_1.title')}</h3>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_1.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_1.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_1.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_2.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_2.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_2.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_3.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_3.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_3.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_4.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_4.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_4.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_5.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_5.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_5.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_6.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_6.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_6.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_7.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_7.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_7.text_2')}
                  <b>{I18n.t('global.terms.section_1.paragraph_7.bold_2')}</b>
                  {I18n.t('global.terms.section_1.paragraph_7.text_3')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_8.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_8.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_8.text_2')}
                  {" "}
                  <a href={privacy_policy} target='_blank' download>here</a>
                  .
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_9.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_9.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_9.text_2')}
                  <b>{I18n.t('global.terms.section_1.paragraph_9.bold_2')}</b>
                  {I18n.t('global.terms.section_1.paragraph_9.text_3')}
                  <b>{I18n.t('global.terms.section_1.paragraph_9.bold_3')}</b>
                  {I18n.t('global.terms.section_1.paragraph_9.text_4')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_10.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_10.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_10.text_2')}
                  <b>{I18n.t('global.terms.section_1.paragraph_10.bold_2')}</b>
                  {I18n.t('global.terms.section_1.paragraph_10.text_3')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_11.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_11.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_11.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_12.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_12.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_12.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_13.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_13.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_13.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_14.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_14.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_14.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_15.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_15.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_15.text_2')}
                  <b>{I18n.t('global.terms.section_1.paragraph_15.bold_2')}</b>
                  {I18n.t('global.terms.section_1.paragraph_15.text_3')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_16.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_16.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_16.text_2')}
                  <b>{I18n.t('global.terms.section_1.paragraph_16.bold_2')}</b>
                  {I18n.t('global.terms.section_1.paragraph_16.text_3')}
                  <b>{I18n.t('global.terms.section_1.paragraph_16.bold_3')}</b>
                  {I18n.t('global.terms.section_1.paragraph_16.text_4')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_17.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_17.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_17.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_18.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_18.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_18.text_2')}
                </p>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_19.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_19.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_19.text_2')}
                </p>
                <p>{I18n.t('global.terms.section_1.paragraph_20.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_21.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_22.text_1')}</p>

                <h4>{I18n.t('global.terms.section_1.subtitle_1')}</h4>
                <p><b>{I18n.t('global.terms.section_1.paragraph_23.bold_1')}</b></p>
                <p>{I18n.t('global.terms.section_1.paragraph_24.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_25.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_26.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_27.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_27.list.item_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_27.list.item_2')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_27.list.item_3')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_27.list.item_4')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_28.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_29.text_1')}</p>
                <h4>{I18n.t('global.terms.section_1.subtitle_2')}</h4>
                <p>
                  {I18n.t('global.terms.section_1.paragraph_30.text_1')}
                  <b>{I18n.t('global.terms.section_1.paragraph_30.bold_1')}</b>
                  {I18n.t('global.terms.section_1.paragraph_30.text_2')}
                </p>
                <p>{I18n.t('global.terms.section_1.paragraph_31.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_32.text_1')}</p>
                <h4>{I18n.t('global.terms.section_1.subtitle_3')}</h4>
                <p>{I18n.t('global.terms.section_1.paragraph_33.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_1.paragraph_33.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_1.paragraph_33.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_1.paragraph_33.list.item_3')}</li>
                  <li>{I18n.t('global.terms.section_1.paragraph_33.list.item_4')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_1.paragraph_34.text_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_34.list.item_1')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_34.list.item_2')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_34.list.item_3')}</p>
                <p>{I18n.t('global.terms.section_1.paragraph_35.text_1')}</p>

                <h3>{I18n.t('global.terms.section_2.title')}</h3>
                <p>{I18n.t('global.terms.section_2.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_2.paragraph_1.list.item_1')}</p>
                <p>{I18n.t('global.terms.section_2.paragraph_1.list.item_2')}</p>
                <p>{I18n.t('global.terms.section_2.paragraph_1.list.item_3')}</p>
                <p>{I18n.t('global.terms.section_2.paragraph_1.list.item_4')}</p>
                <p>{I18n.t('global.terms.section_2.paragraph_1.list.item_5')}</p>
                <p>{I18n.t('global.terms.section_2.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_2.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.section_2.paragraph_4.text_1')}</p>

                <h3>{I18n.t('global.terms.section_3.title')}</h3>
                <p>{I18n.t('global.terms.section_3.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_3.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_3.paragraph_3.text_1')}</p>

                <h3>{I18n.t('global.terms.section_4.title')}</h3>
                <p>{I18n.t('global.terms.section_4.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_4.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_4.paragraph_3.text_1')}</p>

                <h3>{I18n.t('global.terms.section_5.title')}</h3>
                <p>{I18n.t('global.terms.section_5.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_5.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_6.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_7.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_8.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_9.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_10.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_5.paragraph_10.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_5.paragraph_10.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_5.paragraph_10.list.item_3')}</li>
                  <li>{I18n.t('global.terms.section_5.paragraph_10.list.item_4')}</li>
                  <li>{I18n.t('global.terms.section_5.paragraph_10.list.item_5')}</li>
                  <li>{I18n.t('global.terms.section_5.paragraph_10.list.item_6')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_5.paragraph_11.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_12.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_13.text_1')}</p>
                <p>{I18n.t('global.terms.section_5.paragraph_14.text_1')}</p>

                <h3>{I18n.t('global.terms.section_6.title')}</h3>
                <p>{I18n.t('global.terms.section_6.paragraph_1.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_3')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_4')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_5')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_6')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_7')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_8')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_9')}</li>
                  <li>{I18n.t('global.terms.section_6.paragraph_1.list.item_10')}</li>
                </ul>

                <h3>{I18n.t('global.terms.section_7.title')}</h3>
                <p>{I18n.t('global.terms.section_7.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_5.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_6.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_7.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_8.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_9.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_10.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_11.text_1')}</p>
                <p>{I18n.t('global.terms.section_7.paragraph_12.text_1')}</p>

                <h3>{I18n.t('global.terms.section_8.title')}</h3>
                <h4>{I18n.t('global.terms.section_8.subsection_1.title')}</h4>
                <p>{I18n.t('global.terms.section_8.subsection_1.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_8.subsection_1.paragraph_2.text_1')}</p>
                <p>
                  {I18n.t('global.terms.section_8.subsection_1.paragraph_3.text_1')}
                  <b>{I18n.t('global.terms.section_8.subsection_1.paragraph_3.bold_1')}</b>
                  {I18n.t('global.terms.section_8.subsection_1.paragraph_3.text_2')}
                  <b>{I18n.t('global.terms.section_8.subsection_1.paragraph_3.bold_2')}</b>
                  {I18n.t('global.terms.section_8.subsection_1.paragraph_3.text_3')}
                </p>
                <p>{I18n.t('global.terms.section_8.subsection_1.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.terms.section_8.subsection_1.paragraph_5.text_1')}</p>

                <h4>{I18n.t('global.terms.section_8.subsection_2.title')}</h4>
                <p>{I18n.t('global.terms.section_8.subsection_2.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_8.subsection_2.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_8.subsection_2.paragraph_3.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_8.subsection_2.paragraph_3.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_8.subsection_2.paragraph_3.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_8.subsection_2.paragraph_3.list.item_3')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_8.subsection_2.paragraph_4.text_1')}</p>

                <h3>{I18n.t('global.terms.section_9.title')}</h3>
                <p>{I18n.t('global.terms.section_9.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_9.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_9.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.section_9.paragraph_4.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_9.paragraph_4.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_9.paragraph_4.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_9.paragraph_4.list.item_3')}</li>
                  <li>{I18n.t('global.terms.section_9.paragraph_4.list.item_4')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_9.paragraph_5.text_1')}</p>

                <h3>{I18n.t('global.terms.section_10.title')}</h3>
                <p>{I18n.t('global.terms.section_10.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_1.list.item_1')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_1.list.item_2')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_1.list.item_3')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_1.list.item_4')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_1.list.item_5')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_2.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_10.paragraph_2.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_10.paragraph_2.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_10.paragraph_2.list.item_3')}</li>
                  <li>{I18n.t('global.terms.section_10.paragraph_2.list.item_4')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_10.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_5.text_1')}</p>
                <p>{I18n.t('global.terms.section_10.paragraph_6.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_10.paragraph_6.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_10.paragraph_6.list.item_2')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_10.paragraph_7.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_10.paragraph_7.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_10.paragraph_7.list.item_2')}</li>
                </ul>

                <h3>{I18n.t('global.terms.section_11.title')}</h3>
                <p>{I18n.t('global.terms.section_11.paragraph_1.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_11.paragraph_1.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_11.paragraph_1.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_11.paragraph_1.list.item_3')}</li>
                </ul>

                <h3>{I18n.t('global.terms.section_12.title')}</h3>
                <p>{I18n.t('global.terms.section_12.paragraph_1.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_12.paragraph_1.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_12.paragraph_1.list.item_2')}</li>
                </ul>
                <h4>{I18n.t('global.terms.section_12.subsection_1.title')}</h4>
                <p>{I18n.t('global.terms.section_12.subsection_1.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_1.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_1.paragraph_3.text_1')}</p>
                <h4>{I18n.t('global.terms.section_12.subsection_2.title')}</h4>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_2.text_1')}</p>
                <p><b>{I18n.t('global.terms.section_12.subsection_2.paragraph_3.bold_1')}</b></p>
                <p><b>{I18n.t('global.terms.section_12.subsection_2.paragraph_4.bold_1')}</b></p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_5.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_6.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_7.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_8.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_9.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_10.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_11.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_12.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_13.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_14.text_1')}</p>
                <p><b>{I18n.t('global.terms.section_12.subsection_2.paragraph_15.bold_1')}</b></p>
                <p>{I18n.t('global.terms.section_12.subsection_2.paragraph_16.text_1')}</p>
                <h4>{I18n.t('global.terms.section_12.subsection_3.title')}</h4>
                <p>{I18n.t('global.terms.section_12.subsection_3.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_3.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_12.subsection_3.paragraph_3.text_1')}</p>
                <h4>{I18n.t('global.terms.section_12.subsection_4.title')}</h4>
                <p>{I18n.t('global.terms.section_12.subsection_4.paragraph_1.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_12.subsection_4.paragraph_1.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_12.subsection_4.paragraph_1.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_12.subsection_4.paragraph_1.list.item_3')}</li>
                  <li>{I18n.t('global.terms.section_12.subsection_4.paragraph_1.list.item_4')}</li>
                  <li>{I18n.t('global.terms.section_12.subsection_4.paragraph_1.list.item_5')}</li>
                  <li>{I18n.t('global.terms.section_12.subsection_4.paragraph_1.list.item_6')}</li>
                  <li>{I18n.t('global.terms.section_12.subsection_4.paragraph_1.list.item_7')}</li>
                </ul>

                <h3>{I18n.t('global.terms.section_13.title')}</h3>
                <p>{I18n.t('global.terms.section_13.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_13.paragraph_2.text_1')}</p>

                <h3>{I18n.t('global.terms.section_14.title')}</h3>
                <p>{I18n.t('global.terms.section_14.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_14.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_14.paragraph_3.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_14.paragraph_3.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_14.paragraph_3.list.item_2')}</li>
                </ul>

                <h3>{I18n.t('global.terms.section_15.title')}</h3>
                <p>{I18n.t('global.terms.section_15.paragraph_1.text_1')}</p>

                <h3>{I18n.t('global.terms.section_16.title')}</h3>
                <p>{I18n.t('global.terms.section_16.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_16.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_16.paragraph_3.text_1')}</p>

                <h3>{I18n.t('global.terms.section_17.title')}</h3>
                <p>{I18n.t('global.terms.section_17.paragraph_1.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_3')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_4')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_5')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_6')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_7')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_8')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_9')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_10')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_12')}</li>
                  <li>{I18n.t('global.terms.section_17.paragraph_1.list.item_13')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_17.paragraph_2.text_1')}</p>

                <h3>{I18n.t('global.terms.section_18.title')}</h3>
                <p>{I18n.t('global.terms.section_18.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.paragraph_3.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_18.paragraph_3.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_18.paragraph_3.list.item_2')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_18.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.paragraph_5.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.paragraph_6.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.paragraph_7.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.paragraph_8.text_1')}</p>
                <h4>{I18n.t('global.terms.section_18.subsection_1.title')}</h4>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_5.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_6.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_6.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_6.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_6.list.item_3')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_7.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_8.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_2')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_3')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_4')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_5')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_6')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_7')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_8')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_9')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_10')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_11')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_12')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_13')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_14')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_15')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_16')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_17')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_18')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_19')}</li>
                  <li>{I18n.t('global.terms.section_18.subsection_1.paragraph_9.list.item_20')}</li>
                </ul>
                <p>{I18n.t('global.terms.section_18.subsection_1.paragraph_10.text_1')}</p>
                <h4>{I18n.t('global.terms.section_18.subsection_2.title')}</h4>
                <p>{I18n.t('global.terms.section_18.subsection_2.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_2.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_2.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.section_18.subsection_2.paragraph_4.text_1')}</p>

                <h3>{I18n.t('global.terms.section_19.title')}</h3>
                <p>{I18n.t('global.terms.section_19.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_19.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_19.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.terms.section_19.paragraph_4.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.terms.section_19.paragraph_4.list.item_1')}</li>
                  <li>{I18n.t('global.terms.section_19.paragraph_4.list.item_2')}</li>
                </ul>

                <h3>{I18n.t('global.terms.section_20.title')}</h3>
                <p>{I18n.t('global.terms.section_20.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_20.paragraph_2.text_1')}</p>

                <h3>{I18n.t('global.terms.section_21.title')}</h3>
                <p>{I18n.t('global.terms.section_21.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.terms.section_21.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.terms.section_21.paragraph_3.text_1')}</p>

              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
TosModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func
};
export default TosModal;