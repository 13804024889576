import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import editIcon from '../../assets/images/icons/edit-solid.svg';
import CardanoAddressModal from './CardanoAddressModal';
import KycProcedureModal from './KycProcedureModal';
import { launchWebSdk, pollForVerificationStatusOnServer, getAccessToken } from '../services/kyc';

class InvestCta extends React.Component {
  constructor(props) {
    super(props);
    const kycReviewRejectType = ['FINAL', 'RETRY'].includes(props.user.kycReviewRejectType) ? props.user.kycReviewRejectType : null;
    this.state = {
      cardanoAddressModalOpen: false,
      kycProcedureModalOpen: false,
      userKycStatus: props.user.kycVerificationStatus,
      kycReviewRejectType,
    };
  }

  toggleCardanoAddressModal = () => {
    this.setState({ cardanoAddressModalOpen: !this.state.cardanoAddressModalOpen });
  };

  toggleKycProcedureModal = () => {
    this.setState({ kycProcedureModalOpen: !this.state.kycProcedureModalOpen });
  };

  handleKycResponse = (payload) => {
    const { userKycStatus } = this.state;
    const targetedStatus = payload.reviewStatus;
    const { reviewResult } = payload;
    if (targetedStatus === 'completed' && userKycStatus === 'verified') return;
    if (targetedStatus === 'pending' && userKycStatus !== 'pending') {
      pollForVerificationStatusOnServer('pending')
        .then(response => {
          this.setState({ userKycStatus: response });
        });
    } else if (targetedStatus === 'completed' && reviewResult.reviewAnswer === 'GREEN' && userKycStatus !== 'verified') {
      pollForVerificationStatusOnServer('verified')
        .then(response => {
          this.setState({
            userKycStatus: response,
          });
        });
    } else if (targetedStatus === 'completed' && reviewResult.reviewAnswer === 'RED' && userKycStatus !== 'rejected') {
      pollForVerificationStatusOnServer('rejected')
        .then(response => {
          this.setState({
            userKycStatus: response,
            kycReviewRejectType: get(reviewResult, 'reviewRejectType'),
          });
        });
    }
  };

  launchWebSdkHandler = (iframeDivId) => {
    getAccessToken()
      .done(response => {
        launchWebSdk(response.token, iframeDivId, this.handleKycResponse);
      })
      .fail(e => {
        console.log(`Error: ${e.responseJSON.error.description}`);
      });
  };

  isKycPending = () => this.state.userKycStatus === 'pending';

  isKycVerified = () => this.state.userKycStatus === 'verified';

  isKycRejected = () => this.state.userKycStatus === 'rejected';

  isKysRejectionAnswerFinal = () => this.state.kycReviewRejectType === 'FINAL';

  showStartKycProcedure = () => !['pending', 'rejected', 'verified'].includes(this.state.userKycStatus);

  render () {
    const { user } = this.props;
    const { cardanoAddressModalOpen, kycProcedureModalOpen } = this.state;

    return (
      <div className="invest-cta">
        <div className="invest-cta-header">
          {I18n.t('global.referrals.profile.ready_to_invest')}
        </div>
        <div className="invest-cta-subheader">
          {I18n.t('global.referrals.profile.ready_to_invest_subheader_1')}
        </div>
        <div className="invest-cta-body">
          <div className="invest-step" onClick={this.toggleCardanoAddressModal.bind(this)} id="call-cardano-address-modal">
            <div className={`invest-step-mark ${user.returnAddress && 'done'}`}>
              {user.returnAddress && <i className="fa fa-check" />}
            </div>
            <div className="invest-step-description">
              {I18n.t('global.referrals.profile.provide_cardano_address')}
              <div className="description">
                {I18n.t('global.referrals.profile.provide_cardano_address_description')}
              </div>
              <div className="invest-button-wrapper">
                <div className="invest-button">
                  {user.returnAddress ? (
                    <>
                      {I18n.t('global.referrals.profile.edit_cardano_address')}
                    </>
                  ) : (
                    <>
                      {I18n.t('global.referrals.profile.provide_cardano_address')}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {this.showStartKycProcedure() && (
            <div className="invest-step" onClick={this.toggleKycProcedureModal} id="go-through-kyc">
              <div className="invest-step-mark">
                <i className="fa fa-check" />
              </div>
              <div className="invest-step-description">
                {I18n.t('global.referrals.profile.go_through_kyc')}
                <div className="description">
                  {I18n.t('global.referrals.profile.kyc_description')}
                </div>
                <div className="invest-button-wrapper">
                  <div className="invest-button">
                    {I18n.t('global.referrals.profile.start_procedure')}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            style={{ display: this.isKycPending() ? 'flex' : 'none' }}
            className="invest-step open-modal"
            onClick={this.toggleKycProcedureModal}
            id="kyc-pending"
          >
            <div className="invest-step-spinner" />
            <div className="invest-step-description">
              {I18n.t('global.referrals.profile.wait_for_kyc')}
            </div>
          </div>

          <div
            style={{ display: this.isKycVerified() ? 'flex' : 'none' }}
            className="invest-step non-clickable"
            id="kyc-complete"
          >
            <div className="invest-step-mark done">
              <i className="fa fa-check" />
            </div>
            <div className="invest-step-description">
              {I18n.t('global.referrals.profile.kyc_complete')}
            </div>
          </div>

          <div
            style={{ display: this.isKycRejected() && this.isKysRejectionAnswerFinal() ? 'flex' : 'none' }}
            className="invest-step non-clickable"
            id="kyc-failed"
          >
            <div className="invest-step-mark failed">
              <i className="fa fa-times" />
            </div>
            <div className="invest-step-description with-link">
              {I18n.t('global.referrals.profile.kyc_failed')}&nbsp;
              (<a href="mailto:support@smartcontract.hr">
                {I18n.t('global.referrals.profile.contact_us')}
              </a>)
            </div>
          </div>

          <div
            style={{ display: this.isKycRejected() && !this.isKysRejectionAnswerFinal() ? 'flex' : 'none' }}
            className="invest-step open-modal"
            onClick={this.toggleKycProcedureModal}
            id="kyc-failed-retry"
          >
            <div className="invest-step-mark failed">
              <i className="fa fa-times" />
            </div>
            <div className="invest-step-description">
              {I18n.t('global.referrals.profile.kyc_failed')}

              <div className="description">
                {I18n.t('global.referrals.profile.kyc_failed_retry_description')}
              </div>
              <div className="invest-button-wrapper">
                <div className="invest-button">
                  {I18n.t('global.referrals.profile.try_again')}
                </div>
              </div>
            </div>
          </div>

        </div>
        <CardanoAddressModal
          user={user}
          open={cardanoAddressModalOpen}
          toggle={this.toggleCardanoAddressModal.bind(this)}
          updateUserData={this.props.updateUserData}
        />
        <KycProcedureModal
          open={kycProcedureModalOpen}
          launchWebSdkHandler={this.launchWebSdkHandler}
          toggle={this.toggleKycProcedureModal}
        />
      </div>
    );
  }
}

InvestCta.propTypes = {
  user: PropTypes.object,
  updateUserData: PropTypes.func,
};
export default InvestCta;
