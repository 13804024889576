import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CountryDropdown } from 'react-country-region-selector';
import PropTypes from "prop-types";
import I18n from "i18n-js";

class TokenSaleApplyModal extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedOption: 'Person',
      country: 'Croatia',
      captchaKey: !productionEnvironment ? captchaProduction : captchaDevelopment,
      disableSubmit: true,
    };
  }

  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value
    });
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  onCaptchaValidate(value) {
    if(value) this.setState({ disableSubmit: false });
  }

  render () {
    const { open, toggle } = this.props;
    const { selectedOption } = this.state;
    const { country } = this.state;
    const { captchaKey } = this.state;
    const { disableSubmit } = this.state;
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    return (
      <>
        {open && (
          <div id="token-sale-apply-modal">
            <div className="modal-bg" onClick={toggle}></div>
            <div className="modal-wrapper">
              <div className="modal-header">
                <div className="column">
                  <h2>{I18n.t('global.token_sale.invest.contact')}</h2>
                  <div className="modal-subheader">
                    {I18n.t('global.token_sale.apply_modal.apply_notice')}
                  </div>
                </div>
                <div className="exit-modal" onClick={toggle}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
              <div className="modal-content">

                  <div className="radio-buttons-wrapper">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          defaultValue="Person"
                          checked={selectedOption === "Person"}
                          onChange={this.onValueChange.bind(this)}
                        />
                         {I18n.t('global.token_sale.apply_modal.person')}
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          defaultValue="Company"
                          checked={selectedOption === "Company"}
                          onChange={this.onValueChange.bind(this)}
                        />
                         {I18n.t('global.token_sale.apply_modal.company')}
                      </label>
                    </div>
                  </div>
                  {selectedOption === 'Person' ? (
                    <div id="personForm">
                      <form action="/send_private_sale_application_email" acceptCharset="UTF-8" method="post">
                        <input type="hidden" name="authenticity_token" defaultValue={csrf} />
                        <input value="person" type="hidden" name="private_sale_type" id="private_sale_type" />
                        <div className="input-wrapper">
                          <input autoComplete="given_name" placeholder={I18n.t('global.login.placeholders.first_name')} type="text" name="first_name" id="first_name" />
                        </div>
                        <div className="input-wrapper">
                          <input autoComplete="family-name" placeholder={I18n.t('global.login.placeholders.last_name')} type="text" name="last_name" id="last_name" />
                        </div>
                        <div className="input-wrapper">
                          <input autoComplete="email" placeholder={I18n.t('global.login.placeholders.email')} type="email" name="email" id="email" />
                        </div>
                        <div className="selectbox-wrapper">
                          <CountryDropdown value={country} onChange={(val) => this.selectCountry(val)} name="country" id="country" />
                          <i className="fa fa-chevron-down"></i>
                        </div>
                        <div className="input-wrapper">
                          <input autoComplete="tel" placeholder={I18n.t('global.token_sale.apply_modal.phone_number')} id="phone-number-field-apply-modal" type="text" name="phone_number" />
                        </div>
                        <div className="captcha">
                          <span>
                            {I18n.t('global.captcha.captcha_1')}<br />
                            {I18n.t('global.captcha.captcha_2')}
                          </span>
                          <ReCAPTCHA
                            sitekey={captchaKey}
                            onChange={this.onCaptchaValidate.bind(this)}
                          />
                        </div>
                        <div className="input-wrapper">
                          <input type="submit" name="commit" defaultValue={I18n.t('global.token_sale.apply_modal.submit')} disabled={disableSubmit} data-disable-with="Submit" />
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div id="companyForm">
                      <form action="/send_private_sale_application_email" acceptCharset="UTF-8" method="post">
                        <input type="hidden" name="authenticity_token" defaultValue={csrf} /> 
                        <input value="company" type="hidden" name="private_sale_type" id="private_sale_type" />
                        <div className="input-wrapper">
                          <input autoComplete="company_registration_number" placeholder={I18n.t('global.token_sale.apply_modal.company_registration_number')} type="text" name="company_registration_number" id="company_registration_number" />
                        </div>
                        <div className="input-wrapper">
                          <input autoComplete="company-name" placeholder={I18n.t('global.token_sale.apply_modal.company_name')} type="text" name="company_name" id="company_name" />
                        </div>
                        <div className="input-wrapper">
                          <input autoComplete="email" placeholder={I18n.t('global.login.placeholders.email')} type="email" name="email" id="email" />
                        </div>
                        <div className="input-wrapper">
                          <input autoComplete="address" placeholder={I18n.t('global.login.placeholders.address')} type="text" name="address" id="address" />
                        </div>
                        <div className="selectbox-wrapper">
                          <CountryDropdown value={country} onChange={(val) => this.selectCountry(val)} name="country" id="country" />
                          <i className="fa fa-chevron-down"></i>
                        </div>
                        <div className="input-wrapper">
                          <input autoComplete="full_name" placeholder={I18n.t('global.token_sale.apply_modal.representative_full_name')} type="text" name="representative_full_name" id="representative_full_name" />
                        </div>
                        <div className="input-wrapper">
                          <input autoComplete="tel" placeholder={I18n.t('global.token_sale.apply_modal.phone_number')} id="phone-number-field-apply-modal" type="text" name="phone_number" />
                        </div>
                        <div className="captcha">
                          <span>
                            {I18n.t('global.captcha.captcha_1')}<br />
                            {I18n.t('global.captcha.captcha_2')}
                          </span>
                          <ReCAPTCHA
                            sitekey={captchaKey}
                            onChange={this.onCaptchaValidate.bind(this)}
                          />
                        </div>
                        <div className="input-wrapper">
                          <input type="submit" name="commit" defaultValue={I18n.t('global.token_sale.apply_modal.submit')} disabled={disableSubmit} data-disable-with="Submit" />
                        </div>
                      </form>
                    </div>
                  )}

              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
TokenSaleApplyModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func
};
export default TokenSaleApplyModal;