import React from "react";
import PropTypes from "prop-types";

class PpModal extends React.Component {
  render () {
    const { open, toggle } = this.props;
    return (
      <>
        {open && (
          <div id="pp-modal">
            <div className="modal-bg" onClick={toggle}></div>
            <div className="modal-wrapper">
              <div className="modal-header">
                <h2>{I18n.t('global.footer.links.privacy_policy')}</h2>
                <div className="exit-modal" onClick={toggle}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
              <div className="modal-content">
                <p>{I18n.t('global.privacy_policy.subtitle')}</p>
                <p>{I18n.t('global.privacy_policy.welcome')}</p>
                <p>
                  {I18n.t('global.privacy_policy.paragraph_1.text_1')}
                  <b>{I18n.t('global.privacy_policy.paragraph_1.bold_1')}</b>
                  {I18n.t('global.privacy_policy.paragraph_1.text_2')}
                  <b>{I18n.t('global.privacy_policy.paragraph_1.bold_2')}</b>
                  {I18n.t('global.privacy_policy.paragraph_1.text_3')}
                  <b>{I18n.t('global.privacy_policy.paragraph_1.bold_3')}</b>
                  {I18n.t('global.privacy_policy.paragraph_1.text_4')}
                </p>
                <p>{I18n.t('global.privacy_policy.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.paragraph_5.text_1')}</p>
                <p><b>{I18n.t('global.privacy_policy.paragraph_6.text_1')}</b></p>

                <h4>{I18n.t('global.privacy_policy.section_1.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_2.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_3.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_4.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_5.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_6.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_7.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_8.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_9.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_10.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_11.title')}</h4>
                <h4>{I18n.t('global.privacy_policy.section_12.title')}</h4>

                <h3>{I18n.t('global.privacy_policy.section_1.title')}</h3>
                <p>
                  {I18n.t('global.privacy_policy.section_1.paragraph_1.text_1')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_1.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_1.text_2')}
                </p>
                <p>
                  {I18n.t('global.privacy_policy.section_1.paragraph_2.text_1')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_2.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_2.text_2')}
                </p>
                <p>
                  {I18n.t('global.privacy_policy.section_1.paragraph_3.text_1')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_3.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_3.text_2')}
                </p>
                <p>
                  {I18n.t('global.privacy_policy.section_1.paragraph_4.text_1')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_4.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_4.text_2')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_4.bold_2')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_4.text_3')}
                </p>
                <p>
                  {I18n.t('global.privacy_policy.section_1.paragraph_5.text_1')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_5.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_5.text_2')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_5.bold_2')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_5.text_3')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_5.bold_3')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_5.text_4')}
                </p>
                <p>
                  {I18n.t('global.privacy_policy.section_1.paragraph_6.text_1')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_6.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_6.text_2')}
                </p>
                <p>
                  {I18n.t('global.privacy_policy.section_1.paragraph_7.text_1')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_7.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_7.text_2')}
                </p>
                <p>
                  {I18n.t('global.privacy_policy.section_1.paragraph_8.text_1')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_8.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_8.text_2')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_8.bold_2')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_8.text_3')}
                  <b>{I18n.t('global.privacy_policy.section_1.paragraph_8.bold_3')}</b>
                  {I18n.t('global.privacy_policy.section_1.paragraph_8.text_4')}
                </p>

                <h3>{I18n.t('global.privacy_policy.section_2.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_2.paragraph_1.text_1')}</p>

                <h3>{I18n.t('global.privacy_policy.section_3.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_3.paragraph_1.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.privacy_policy.section_3.list.item_1')}</li>
                  <li>{I18n.t('global.privacy_policy.section_3.list.item_2')}</li>
                  <li>{I18n.t('global.privacy_policy.section_3.list.item_3')}</li>
                </ul>

                <h3>{I18n.t('global.privacy_policy.section_4.title')}</h3>
                <div className="modal-table">
                  <div className="modal-row row-header row-2">
                    <span className="column-title">{I18n.t('global.privacy_policy.section_4.table.column_1.title')}</span>
                    <span className="column-title">{I18n.t('global.privacy_policy.section_4.table.column_2.title')}</span>
                    <span className="column-title">{I18n.t('global.privacy_policy.section_4.table.column_3.title')}</span>
                    <span className="column-title">{I18n.t('global.privacy_policy.section_4.table.column_4.title')}</span>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_1.bold_1')}</b></span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_1')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_1')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_1')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <div className="cell-vertical">
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_2.bold_1')}</b></span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_2.text_1')}</span>
                      </div>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_2')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_2')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_2')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <div className="cell-vertical">
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_3.bold_1')}</b></span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_3.text_1')}</span>
                      </div>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_3')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_3')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_3')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <div className="cell-vertical">
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_4.bold_1')}</b></span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_4.text_1')}</span>
                      </div>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_4')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_4')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_4')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_5.bold_1')}</b></span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_5')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_5')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_5')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <div className="cell-vertical">
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_6.bold_1')}</b></span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_6.text_1')}</span>
                      </div>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_6')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_6')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_6')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <div className="cell-vertical">
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_7.bold_1')}</b></span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_7.text_1')}</span>
                      </div>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_7')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_7')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_7')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_8.bold_1')}</b></span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_8')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_8')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_8')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <div className="cell-vertical">
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_9.bold_1')}</b></span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_9.text_1')}</span>
                      </div>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_9')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_9')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_9')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_10.bold_1')}</b></span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_10')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_10')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_10')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_1.title')}
                      </span>
                      <div className="cell-vertical">
                      <span><b>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_11.bold_1')}</b></span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_1.cell_11.text_1')}</span>
                      </div>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_2.cell_11')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_3.cell_11')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_4.table.column_4.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_4.table.column_4.cell_11')}</span>
                    </div>
                  </div>
                </div>

                <h3>{I18n.t('global.privacy_policy.section_5.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_5.paragraph_1.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.privacy_policy.section_5.list.item_1')}</li>
                  <li>{I18n.t('global.privacy_policy.section_5.list.item_2')}</li>
                  <li>{I18n.t('global.privacy_policy.section_5.list.item_3')}</li>
                </ul>

                <h3>{I18n.t('global.privacy_policy.section_6.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_6.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_6.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_6.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_6.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_6.paragraph_5.text_1')}</p>
                <p><b>{I18n.t('global.privacy_policy.section_6.subtitle')}</b></p>
                <p>{I18n.t('global.privacy_policy.section_6.paragraph_6.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_6.paragraph_7.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_6.paragraph_8.text_1')}</p>

                <h3>{I18n.t('global.privacy_policy.section_7.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_7.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_7.paragraph_2.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_7.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_7.paragraph_4.text_1')}</p>

                <div className="modal-table">
                  <div className="modal-row row-header row-3">
                    <span className="column-title">{I18n.t('global.privacy_policy.section_7.table.column_1.title')}</span>
                    <span className="column-title">{I18n.t('global.privacy_policy.section_7.table.column_2.title')}</span>
                    <span className="column-title">{I18n.t('global.privacy_policy.section_7.table.column_3.title')}</span>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_1.cell_1')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_2.cell_1')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_3.cell_1')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_1.cell_2')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_2.cell_2')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_3.cell_2')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_1.cell_3')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_2.cell_3')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_3.cell_3')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_1.cell_4')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_2.cell_4')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_3.cell_4')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_1.cell_5')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_2.cell_5')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_3.cell_5')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_1.cell_6')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_2.cell_6')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.privacy_policy.section_7.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.privacy_policy.section_7.table.column_3.cell_6')}</span>
                    </div>
                  </div>
                </div>

                <p>{I18n.t('global.privacy_policy.section_7.paragraph_5.text_1')}</p>

                <h3>{I18n.t('global.privacy_policy.section_8.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_8.paragraph_1.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.privacy_policy.section_8.list.item_1')}</li>
                  <li>{I18n.t('global.privacy_policy.section_8.list.item_2')}</li>
                  <li>{I18n.t('global.privacy_policy.section_8.list.item_3')}</li>
                </ul>

                <h3>{I18n.t('global.privacy_policy.section_9.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_9.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_9.paragraph_2.text_1')}</p>

                <h3>{I18n.t('global.privacy_policy.section_10.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_1.text_1')}</p>
                <p>
                  <b>{I18n.t('global.privacy_policy.section_10.paragraph_2.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_10.paragraph_2.text_1')}
                </p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_3.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_4.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_5.text_1')}</p>
                <p>
                  <b>{I18n.t('global.privacy_policy.section_10.paragraph_6.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_10.paragraph_6.text_1')}
                </p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_7.text_1')}</p>
                <p>
                  <b>{I18n.t('global.privacy_policy.section_10.paragraph_8.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_10.paragraph_8.text_1')}
                </p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_9.text_1')}</p>
                <ul>
                  <li>{I18n.t('global.privacy_policy.section_10.paragraph_9.list_1.item_1')}</li>
                  <li>{I18n.t('global.privacy_policy.section_10.paragraph_9.list_1.item_2')}</li>
                  <li>{I18n.t('global.privacy_policy.section_10.paragraph_9.list_1.item_3')}</li>
                  <li>{I18n.t('global.privacy_policy.section_10.paragraph_9.list_1.item_4')}</li>
                  <li>{I18n.t('global.privacy_policy.section_10.paragraph_9.list_1.item_5')}</li>
                </ul>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_9.text_2')}</p>
                <ul>
                  <li>{I18n.t('global.privacy_policy.section_10.paragraph_9.list_2.item_1')}</li>
                  <li>{I18n.t('global.privacy_policy.section_10.paragraph_9.list_2.item_2')}</li>
                </ul>
                <p>
                  <b>{I18n.t('global.privacy_policy.section_10.paragraph_10.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_10.paragraph_10.text_1')}
                </p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_11.text_1')}</p>
                <p>
                  <b>{I18n.t('global.privacy_policy.section_10.paragraph_12.bold_1')}</b>
                  {I18n.t('global.privacy_policy.section_10.paragraph_12.text_1')}
                </p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_13.text_1')}</p>
                <p><b>{I18n.t('global.privacy_policy.section_10.paragraph_14.bold_1')}</b></p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_15.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_16.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_10.paragraph_17.text_1')}</p>

                <h3>{I18n.t('global.privacy_policy.section_11.title')}</h3>
                <p>{I18n.t('global.privacy_policy.section_11.paragraph_1.text_1')}</p>
                <p>{I18n.t('global.privacy_policy.section_11.paragraph_2.text_1')}</p>

                <h3>{I18n.t('global.privacy_policy.section_12.title')}</h3>
                <p><b>{I18n.t('global.privacy_policy.section_12.paragraph_1.bold_1')}</b></p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
PpModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func
};
export default PpModal;