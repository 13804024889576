$(document).ready(function () {
  $('body').on('focus', '.custom-datepicker', function (event) {
    var limitDate = new Date();
    limitDate = limitDate.setFullYear(limitDate.getFullYear()-18);

    $(this).datepicker({
      format: {
        toDisplay: function (date) {
          var displayDate = new Date(date).toISOString().split('T')[0];
          return displayDate;
        },
        toValue: function (date) {
          var valDate = new Date(date).toLocaleString().split(',')[0];
          return valDate;
        },
      },
      weekStart: 1,
      todayHighlight: true,
      autoclose: true,
      startDate: new Date('1/1/1900'),
      endDate: new Date(limitDate),
      container: $(this).parent(),
    });
  });
});
