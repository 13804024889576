import React from "react";
import whitepaper_en from "../../assets/images/pdf/whitepaper-en.pdf";
import whitepaper_hr from "../../assets/images/pdf/whitepaper-hr.pdf";
import whitepaper_de from "../../assets/images/pdf/whitepaper-de.pdf";
import whitepaper_tr from "../../assets/images/pdf/whitepaper-tr.pdf";
import whitepaper_es from "../../assets/images/pdf/whitepaper-es.pdf";

class ReferralEffect extends React.Component {
  render () {
    let step4Content;
    if (I18n.locale === 'hr') {
      step4Content = <span>Pročitajte <a href={whitepaper_hr} target='_blank' download>Whitepaper</a> i pripremite se za Token sale i pratite naše društvene mreže, blog i newsletter</span>;
    } else if (I18n.locale === 'de') {
      step4Content = <span>Lesen Sie <a href={whitepaper_de} target='_blank' download>Whitepaper</a> und machen Sie sich bereit für den Token-Verkauf und folgen Sie unseren sozialen Netzwerken, Blogs und Newslettern</span>
    } else if (I18n.locale === 'tr') {
      step4Content = <span><a href={whitepaper_tr} target='_blank' download>Whitepaper</a>'ı okuyun ve Token satışına hazırlanın ve sosyal ağlarımızı, blogumuzu ve haber bültenimizi takip edin</span>
    } else if (I18n.locale === 'es') {
      step4Content = <span>Lea <a href={whitepaper_es} target='_blank' download>Whitepaper</a> y prepárese para la venta de Token y siga nuestras redes sociales, blog y newsletter</span>
    } else {
      step4Content = <span>Read <a href={whitepaper_en} target='_blank' download>Whitepaper</a> and get ready for Token sale and follow our social networks, blog and newsletter</span>
    }

    return (
      <div className="referral-effect-section">
        <div className="referral-effect-wrapper">
          <h3 className="header">
            {I18n.t('global.referrals.referral_effect.header')}
          </h3>
          <div className="referral-effect-items-wrapper">
            <div className="item">
              <div className="item-step">1</div>
              <span className="text">
                {I18n.t('global.referrals.referral_effect.step_1')}
              </span>
            </div>
            <div className="item">
              <div className="item-step">2</div>
              <span className="text">
                {I18n.t('global.referrals.referral_effect.step_3')}
              </span>
            </div>
            <div className="item">
              <div className="item-step">3</div>
              <span className="text long">
                {step4Content}
              </span>
            </div>
            <div className="item">
              <div className="item-step">4</div>
              <span className="text">
                <span className="text">{I18n.t('global.referrals.profile.go_through_kyc')}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReferralEffect;