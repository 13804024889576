import { utcToZonedTime } from 'date-fns-tz';
import {
  format, isBefore, isAfter, isFuture,
} from 'date-fns';
import toFinite from 'lodash/toFinite';
import toInteger from 'lodash/toInteger';

export const accessToken = document.querySelector("meta[name='csrf-token']").getAttribute('content');

export const displayDate = (date) => {
  if (!date) return null;

  return format(utcToZonedTime(new Date(date), 'Europe/Berlin'), 'dd.MM. HH:mm');
};

export const displayPrice = (price, max = 2, min = 2) => toFinite(price).toLocaleString('hr-HR', {
  minimumFractionDigits: min,
  maximumFractionDigits: max
});

export const intToString = (num) => {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  const si = [
    { v: 1E3, s: 'K' },
    { v: 1E6, s: 'M' },
    { v: 1E9, s: 'B' },
    { v: 1E12, s: 'T' },
    { v: 1E15, s: 'P' },
    { v: 1E18, s: 'E' },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }

  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
};

export const displayInvestmentMargins = (tier) => `${intToString(toInteger(tier.min_investment))} – ${intToString(toInteger(tier.max_investment))} EUR`;

export const displayMinimumInvestmentMargins = (tier) => toFinite(toInteger(tier.min_investment)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',00 EUR';

export const displayMaximumInvestmentMargins = (tier) => toFinite(toInteger(tier.max_investment)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',00 EUR';

export const displaySpecialInvestmentMargins = (tier, customValue) => `${intToString(toInteger(customValue))} – ${intToString(toInteger(tier.max_investment))} EUR`;

export const displaySpecialMinimumInvestmentMargins = (customValue) => toFinite(toInteger(customValue)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',00 EUR';

export const isOngoing = (tier, time) => isAfter(time, new Date(tier.start_date)) && isBefore(time, new Date(tier.end_date));

export const isInFuture = (tier) => isFuture(new Date(tier.start_date));

export const displayLargeNumber = (number) => toFinite(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const hasPassed = (tier, time) => isAfter(time, new Date(tier.end_date));

export const DEFAULT_POLL_TIME_MILISECONDS = 300000; // 300_000 => 5min

export const DEFAULT_TIER_POLL_TIME_MILISECONDS = 60000; // 60_000 => 1min
