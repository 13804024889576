import React, { Component } from 'react';
import {
  BrowserRouter, Router, Switch, Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import TokenSale from './TokenSale';
import Dashboard from './Dashboard';
import Invest from './Invest';
import Profile from './Profile';
import Navigation from './Navigation';
import Footer from './Footer';
import { accessToken } from '../utils';

jQuery.ajaxSetup({
  beforeSend(xhr) {
    xhr.setRequestHeader('X-CSRF-Token', accessToken);
  },
});

const newHistory = createBrowserHistory();

class ReactApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
    };
  }

  handleUpdateUser = (user) => this.setState({ user });

  render() {
    const { user } = this.state;
    const {
      tokensForNextLevel,
      tokensNeededUntilNextLevel, cardanoAddress,
      registeredUser, transactions, aciGainedByTokenSale, aciGainedByReferrals,
      aciGainedByVouchers, aciGainedByInstantLevelUp, totalAciGained,
      tiers, refunds,
    } = this.props;

    const enLocale = locale === 'en';

    return (
      <BrowserRouter>
        <Router history={newHistory}>
          <Navigation registeredUser={registeredUser} tiers={tiers} />
          <Switch>
            <Route
              path={enLocale ? '/token_sale' : `/${locale}/token_sale`}
              render={() => (
                <Dashboard
                    transactions={transactions}
                    tiers={tiers}
                />
              )}
            />
            <Route
              path={enLocale ? '/dashboard' : `/${locale}/dashboard`}
              render={() => (
                <Dashboard
                  transactions={transactions}
                  tiers={tiers}
                />
              )}
            />
            <Route
              path={enLocale ? '/invest' : `/${locale}/invest`}
              render={() => (
                <Dashboard
                    transactions={transactions}
                    tiers={tiers}
                />
              )}
            />
            <Route
              path={enLocale ? '/profile' : `/${locale}/profile`}
              render={() => (
                <Profile user={user} handleUpdateUser={this.handleUpdateUser} />
              )}
            />
          </Switch>
          <Footer />
        </Router>
      </BrowserRouter>
    );
  }
}

ReactApp.propTypes = {
  user: PropTypes.object,
  tokensForNextLevel: PropTypes.any,
  tokensNeededUntilNextLevel: PropTypes.number,
  cardanoAddress: PropTypes.string,
  registeredUser: PropTypes.bool,
  aciGainedByTokenSale: PropTypes.number,
  aciGainedByReferrals: PropTypes.number,
  aciGainedByVouchers: PropTypes.number,
  aciGainedByInstantLevelUp: PropTypes.number,
  totalAciGained: PropTypes.number,
  tiers: PropTypes.array,
  transactions: PropTypes.array,
  refunds: PropTypes.array,
};
export default ReactApp;
