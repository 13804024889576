$(document).ready(function () {
  $(".open-modal").on("click", function () {
    var caller = $(this).attr("id");

    if (caller == "call-privacy-policy") {
      $("#modal-privacy-policy").css("display", "block");
    } else if (caller == "call-terms") {
      $("#modal-terms").css("display", "block");
    } else if (caller == "call-faq") {
      $("#modal-faq").css("display", "block");
    } else if (caller == "call-cardano-address-modal") {
      $("#cardano-address-modal").css("display", "block");
    } else if (caller == "call-token-sale-rules-modal") {
      $("#token-sale-rules-modal").css("display", "block");
    } else if (caller == "call-token-sale-apply-modal") {
      $("#token-sale-apply-modal").css("display", "block");
    }

    $("#modal").css("display", "flex");
  });

  $(".exit-modal, .exit-newsletter-modal").on("click", function () {
    $("#modal").css("display", "none");
    $("#modal-privacy-policy").css("display", "none");
    $("#modal-terms").css("display", "none");
    $("#modal-faq").css("display", "none");
    $("#modal-newsletter").css("display", "none");
    $("#cardano-address-modal").css("display", "none");
    $("#kyc-procedure-modal").css("display", "none");
    $("#token-sale-rules-modal").css("display", "none");
    $("#token-sale-apply-modal").css("display", "none");
  });

  if (userSignedIn === 'true') {
    localStorage.setItem('registered_user', true);
  }

  $(window).on("scroll", function () {
    const scrollOffset = $(document).scrollTop() >= $(document).height() / 3;
    const checkModal = $("#modal").css("display");
    const newsletterDismissed = sessionStorage.getItem('newsletter_dismissed');
    const userSubscribed = localStorage.getItem('user_subscribed');
    const registeredUser = localStorage.getItem('registered_user');

    if (userSignedIn === 'false' && newsletterDismissed !== 'true' && userSubscribed !== 'true' && registeredUser !== 'true') {
      if (scrollOffset && checkModal === "none") {
        $("#modal-newsletter").css("display", "flex");
        $("#modal").css("display", "flex");
      }
    }
  });

  $("#modal-newsletter, .exit-newsletter-modal").on("click", function (event) {
    sessionStorage.setItem('newsletter_dismissed', true)
    var id = $(event.target).attr("id");
    if (id == "modal-newsletter") {
      $("#modal").css("display", "none");
      $("#modal-newsletter").css("display", "none");
    }
  });

  $("#newsletter-subscribe-btn").on("click", function (event) {
    localStorage.setItem('user_subscribed', true);
  });

  $("#modal").on("click", function (event) {
    var id = $(event.target).attr("id");
    if (id == "modal") {
      $("#modal").css("display", "none");
      $("#modal-privacy-policy").css("display", "none");
      $("#modal-terms").css("display", "none");
      $("#modal-faq").css("display", "none");
      $("#modal-newsletter").css("display", "none");
      $("#cardano-address-modal").css("display", "none");
      $("#kyc-procedure-modal").css("display", "none");
      $("#token-sale-rules-modal").css("display", "none");
      $("#token-sale-apply-modal").css("display", "none");
    }
  });
});
