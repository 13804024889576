$(document).ready(function(){
  // Add class to navigation after scroll
  $(window).scroll(function() {
    let scroll = $(window).scrollTop();
    let uspHeight = $('.navigation').height();
    if (scroll >= 50) {
      $(".navigation").addClass("scrolled");
    } else {
      $(".navigation").removeClass("scrolled");
    }
  });

  // Open mobile menu
  $('.mobile-menu-button').on('click', function(event) {
    $('.mobile-menu').addClass('show');
  });

  // Close mobile menu
  $('.close-menu-button').on('click', function(event) {
    $('.mobile-menu').removeClass('show');
  });

  // Open langugage menu
  $('.language-menu').on('click', function(e) {
    if ($('.language-menu-options').hasClass('show')) {
      $('.language-menu-options').removeClass('show');
      $('.language-menu i').removeClass('show');
      e.stopPropagation();
    } else {
      $('.language-menu-options').addClass('show');
      $('.language-menu i').addClass('show');
      e.stopPropagation();
    }
  });

  $('body').on('click', function(e) {
    $('.language-menu-options').removeClass('show');
    $('.language-menu i').removeClass('show');
  });

  // Smooth scroll to section
  $(document).on('click', 'a[href^="#"]', function (e) {
    e.preventDefault();
    $('html, body').stop().animate({
        scrollTop: $($(this).attr('href')).offset().top - 70
    }, 1000);
    $('.mobile-menu').removeClass('show');
  });

  // Open dashboard menu
  $('.user-dashboard-button').on('click', function(event) {
    $('.user-dashboard-wrapper').addClass('show');
  });

  // Close dashboard menu
  $('.close-dashboard-button, .user-dashboard-bg').on('click', function(event) {
    $('.user-dashboard-wrapper').removeClass('show');
  });

  // Fade flash message after 2 seconds
  if ($('.flash').length) {
    setTimeout(function() {
      $('.flash').fadeOut();
    }, 5000 );

    $('.flash').on('click', function(e) {
      $('.flash').fadeOut();
    });
  }
});