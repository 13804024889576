$(document).ready(function() {
  $("input[name='search-articles']").on('keypress',function(e) {
    if (e.which == 13) {
      if (locale == "en") {
        window.location = '/blog?search=' + $("input[name='search-articles']").val();
      } else {
        window.location = '/' + locale + '/blog?search=' + $("input[name='search-articles']").val();
      }
    }
  });
});