import React from "react";
import { Link } from 'react-router-dom';
import whitepaper_en from "../../assets/images/pdf/whitepaper-en.pdf";
import whitepaper_hr from "../../assets/images/pdf/whitepaper-hr.pdf";
import whitepaper_de from "../../assets/images/pdf/whitepaper-de.pdf";
import whitepaper_tr from "../../assets/images/pdf/whitepaper-tr.pdf";
import whitepaper_es from "../../assets/images/pdf/whitepaper-es.pdf";
import logoIcon from '../../assets/images/logo/aci_logo_white.png';
import PpModal from "./PpModal";
import TosModal from "./TosModal";
import FaqModal from "./FaqModal";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      ppModalOpen: false,
      tosModalOpen: false,
      faqModalOpen: false,
      locale: I18n.locale,
    };
  }

  togglePpModal = () => {
    this.setState({ ppModalOpen: !this.state.ppModalOpen })
  };

  toggleTosModal = () => {
    this.setState({ tosModalOpen: !this.state.tosModalOpen })
  };

  toggleFaqModal = () => {
    this.setState({ faqModalOpen: !this.state.faqModalOpen })
  };

  handlePdf = () => {
    if(locale === 'hr') return <a href={whitepaper_hr} target='_blank' download>Whitepaper</a>
    if(locale === 'de') return <a href={whitepaper_de} target='_blank' download>Whitepaper</a>
    if(locale === 'tr') return <a href={whitepaper_tr} target='_blank' download>Whitepaper</a>
    if(locale === 'es') return <a href={whitepaper_es} target='_blank' download>Whitepaper</a>
    return <a href={whitepaper_en} target='_blank' download>Whitepaper</a>
  };

  render () {
    const { ppModalOpen, tosModalOpen, faqModalOpen } = this.state;
    const today = new Date();
    const enLocale = locale === 'en';
    return (
      <footer>
        <div className="react-footer-content-wrapper">
          <img src={logoIcon} alt='Blockademia document verification system Logo' />
          <div className="react-footer-links-wrapper">
            <a href={enLocale ? '/#about-section' : `/${locale}/#about-section`}>
              {I18n.t('global.navigation.links.link_about')}
            </a>
            <a href={enLocale ? '/#dapp-section' : `/${locale}/#dapp-section`}>
              {I18n.t('global.navigation.links.link_dapp')}
            </a>
            <a href={enLocale ? '/#aci-token-section' : `/${locale}/#aci-token-section`}>
              {I18n.t('global.navigation.links.link_aci_token')}
            </a>
            <a href={enLocale ? '/#team-section' : `/${locale}/#team-section`}>
              {I18n.t('global.navigation.links.link_team')}
            </a>
            <a href={enLocale ? '/#roadmap-section' : `/${locale}/#roadmap-section`}>
              {I18n.t('global.navigation.links.link_roadmap')}
            </a>
            {this.handlePdf()}
            <Link to={enLocale ? '/token_sale' : `/${locale}/token_sale`}>
              {I18n.t('global.navigation.links.link_token_sale')}
            </Link>
            <a href={enLocale ? '/blog' : `/${locale}/blog`}>
              {I18n.t('global.navigation.links.link_blog')}
            </a>
          </div>
        </div>

        <div className="react-footer-bottom">
          <div className="react-footer-bottom-content-wrapper">
            <span>© {today.getFullYear()} {I18n.t('global.footer.misc.powered_by')} <a href="https://www.smartcontract.hr/hr/" target="_blank">{I18n.t('global.footer.misc.smart_contract')}</a>.</span>
            <div className="react-footer-bottom-items-wrapper">
              <div className="react-footer-bottom-item" onClick={this.togglePpModal.bind(this)}>
                {I18n.t('global.footer.links.privacy_policy')}
              </div>
              <div className="react-footer-bottom-item" onClick={this.toggleTosModal.bind(this)}>
                {I18n.t('global.footer.links.terms_of_service')}
              </div>
              <div className="react-footer-bottom-item" onClick={this.toggleFaqModal.bind(this)}>
                {I18n.t('global.footer.links.faq')}
              </div>
            </div>
            <span>{I18n.t('global.footer.misc.all_rights')}</span>
          </div>
        </div>
        <PpModal open={ppModalOpen} toggle={this.togglePpModal.bind(this)} />
        <TosModal open={tosModalOpen} toggle={this.toggleTosModal.bind(this)} />
        <FaqModal open={faqModalOpen} toggle={this.toggleFaqModal.bind(this)} />
      </footer>
    );
  }
}

export default Footer;
