import React, { useState } from 'react';

function VoucherSection(props) {
  const [code, setCode] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code && code.length > 0) {
      setDisabled(true);
      jQuery.post('/submit_voucher', { 'voucher[code]': code })
        .done(data => {
          if (data.error) {
            jQuery('body').append(`<div class="flash alert">${data.message}</div>`);
          } else {
            jQuery('body').append(`<div class="flash notice">${data.message}</div>`);
            setCode('');
            jQuery.getJSON('/poll/user_profile', (userData) => {
              props.updateUserData(userData);
            });
          }
          setTimeout(() => {
            jQuery('.flash').fadeOut();
          }, 5000);
          jQuery('.flash').on('click', () => {
            jQuery('.flash').fadeOut();
          });
        })
        .always(() => {
          setDisabled(false);
        });
    }
  };

  return (
    <div className="voucher-section">
      <div className="voucher-link-wrapper">
        <form action="/submit_voucher" acceptCharset="UTF-8" method="post" onSubmit={handleSubmit}>
          <input
            id="voucher_code"
            placeholder={I18n.t('global.referrals.voucher.voucher_input_placeholder')}
            type="text"
            name="voucher[code]"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <input type="submit" name="commit" value={I18n.t('global.referrals.voucher.voucher_submit')} className="submit" disabled={disabled} />
        </form>
      </div>
      <span className="disclamer">
        {I18n.t('global.referrals.voucher.voucher_disclamer')}
      </span>
    </div>
  );
}

export default VoucherSection;
