export const getAccessToken = () => (
  jQuery.ajax('/generate_access_token', {
    method: 'GET',
    dataType: 'json',
  })
);

const getNewAccessToken = () => new Promise(resolve => {
  getAccessToken()
    .done(response => {
      resolve(response.token);
    });
});

const checkVerificationStatusInDatabase = (targetedStatus, attemptsNumber, interval) => {
  let databaseStatusUpdated = false;

  return new Promise(resolve => {
    setTimeout(() => {
      $.ajax('/check_kyc_verification_status', {
        method: 'GET',
        dataType: 'json',
      })
        .done(response => {
          if (response.kyc_verification_status === targetedStatus) {
            databaseStatusUpdated = true;
          }
          attemptsNumber--;
          if (databaseStatusUpdated) {
            resolve(targetedStatus);
          } else if (attemptsNumber > 0) {
            resolve(checkVerificationStatusInDatabase(targetedStatus, attemptsNumber, interval));
          } else {
            resolve(null);
          }
        });
    }, interval);
  });
};

export const pollForVerificationStatusOnServer = (targetedStatus) => {
  // We will ask server for verification status 5 times, with 2-seconds interval between checks
  const attemptsNumber = 10;
  const interval = 1500;

  return new Promise(resolve => {
    checkVerificationStatusInDatabase(targetedStatus, attemptsNumber, interval)
      .then(response => {
        resolve(response);
      });
  });
};

export const launchWebSdk = (accessToken, elSelector, handler) => {
  const snsWebSdkInstance = snsWebSdk.init(
    accessToken,
    // If access-token (session) has expired, this callback (which must return Promise) will get us a new (valid) one.
    () => getNewAccessToken(),
  )
    .withConf({
      lang: 'en', // language of WebSDK texts and comments (ISO 639-1 format)
      email: undefined,
      phone: undefined,
      i18n: undefined, // JSON of custom SDK Translations
      uiConf: {
        customCss: 'https://url.com/styles.css',
      // URL to css file in case you need change it dynamically from the code
      // the similar setting at Customizations tab will rewrite customCss
      // you may also use to pass string with plain styles `customCssStr:`
      },
    })
    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
    .on('idCheck.applicantStatus', handler)
    .build();

  // you are ready to go:
  // just launch the WebSDK by providing the container element for it
  snsWebSdkInstance.launch(elSelector);
};
