import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import toFinite from 'lodash/toFinite';
import startCase from 'lodash/startCase';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import sum from 'lodash/sum';
import toInteger from 'lodash/toInteger';
import { displayPrice, isInFuture, isOngoing } from '../../utils';
import refundIcon from "../../../assets/images/graphics/aci_refund.png";

class TransactionTable extends React.Component {
  getRefundInfo = (txID) => {
    if (this.props.refunds.find(refund => refund.transactions_ids.includes(txID))) {
      return <a href={`https://explorer.cardano.org/en/transaction?id=${this.props.refunds.find(refund => refund.transactions_ids.includes(txID)).refund_transaction_id}`} target="_blank" className="table-action-link"><img src={refundIcon} alt="Blockademia refund icon" /><div className="tooltip">{I18n.t('global.invest.transaction_table.action_tooltip')}</div></a>;
    } else {
      return '-';
    }
  }

  render () {
    const { transactions, tier, time, refunds } = this.props;
    if (!isOngoing(tier, time) && isInFuture(tier)) {
      return null;
    }

    const fromToDisplay = `${format(new Date(tier.start_date), 'dd.MM')} - ${format(new Date(tier.end_date), 'dd.MM.yyyy')}`;
    const total = sum(map(transactions, (t) => toFinite(t.accepted_aci_tokens)));

    return (
      <div className="transaction-table-wrapper">
        <div className="table-top">
          <div className="table-top-text">
            <span className="light">{I18n.t('global.invest.transaction_table.title')} -</span> {tier.name} <span className="divider">|</span> {fromToDisplay} <span className="divider">|</span>   {I18n.t('global.invest.aci_price')} {displayPrice(tier.aci_price)} EUR
          </div>
        </div>
        <div className="transaction-table">
          <div className="table-header">
            <div className="item transaction-number">#</div>
            <div className="item transaction-id">{I18n.t('global.invest.transaction_table.id')}</div>
            <div className="item transaction-date">{I18n.t('global.invest.transaction_table.date')}</div>
            <div className="item transaction-ada">{I18n.t('global.invest.transaction_table.initial_ada_amount')} /<br/>{I18n.t('global.invest.transaction_table.accepted_ada_amount')}</div>
            <div className="item transaction-ada-euro">ADA/EUR</div>
            <div className="item transaction-euro">{I18n.t('global.invest.transaction_table.initial_euro_amount')} /<br/>{I18n.t('global.invest.transaction_table.accepted_euro_amount')}</div>
            <div className="item transaction-aci">{I18n.t('global.invest.transaction_table.accepted_aci_amount')}</div>
            <div className="item transaction-status">{I18n.t('global.invest.transaction_table.status')}</div>
            <div className="item transaction-refunds">{I18n.t('global.invest.transaction_table.for_refund')} /<br/>{I18n.t('global.invest.transaction_table.refunded')}</div>
            <div className="item transaction-actions">{I18n.t('global.invest.transaction_table.actions')}</div>
          </div>
          <div className="table-body">
            {isEmpty(transactions) && (
              <div className="table-row">
                <div className="item">
                  {I18n.t('global.invest.transaction_table.no_transactions')}
                </div>
              </div>
            )}
            {!isEmpty(transactions) && orderBy(transactions, 'occured_at', ['asc']).map((t, i) => (
              <div className="table-row" key={t.transaction_id}>
                <div className="item transaction-number">
                  <div className="mobile-helper">#:</div>
                  <div className="item-value">{i + 1}</div>
                </div>
                <div className="item transaction-id">
                  <div className="mobile-helper">{I18n.t('global.invest.transaction_table.id')}:</div>
                  <div className="item-value"><a href={`https://explorer.cardano.org/en/transaction?id=${t.transaction_id}`} target="_blank">{t.transaction_id}</a></div>
                </div>
                <div className="item transaction-date">
                  <div className="mobile-helper">{I18n.t('global.invest.transaction_table.date')}:</div>
                  <div className="item-value date">{format(new Date(t.occured_at), 'dd.MM.yyyy')},<br />{format(new Date(t.occured_at), 'H:mm:ss')}</div>
                </div>
                <div className="item transaction-ada">
                  <div className="mobile-helper">{I18n.t('global.invest.transaction_table.initial_ada_amount')} /<br/>{I18n.t('global.invest.transaction_table.accepted_ada_amount')}</div>
                  <div className="item-value">{displayPrice(t.initial_ada_amount, 6, 6)} /<br/>{displayPrice(t.accepted_ada_amount, 6, 6)}</div>
                </div>
                <div className="item transaction-ada-euro">
                  <div className="mobile-helper">ADA/EUR:</div>
                  <div className="item-value">{displayPrice(toFinite(t.initial_euro_amount) / toFinite(t.initial_ada_amount))}</div>
                </div>
                <div className="item transaction-euro">
                  <div className="mobile-helper">{I18n.t('global.invest.transaction_table.initial_euro_amount')} /<br/>{I18n.t('global.invest.transaction_table.accepted_euro_amount')}</div>
                  <div className="item-value">{displayPrice(t.initial_euro_amount)} /<br/>{displayPrice(t.accepted_euro_amount)}</div>
                </div>
                <div className="item transaction-aci">
                  <div className="mobile-helper">{I18n.t('global.invest.transaction_table.accepted_aci_amount')}:</div>
                  <div className="item-value">{displayPrice(t.accepted_aci_tokens, 6)}</div>
                </div>
                <div className="item transaction-status">
                  <div className="mobile-helper">{I18n.t('global.invest.transaction_table.status')}:</div>
                  {t.status !== 'processed' ? (
                    <div className="item-value tooltip-wrapper">
                      {startCase(t.status)}
                      <i className="fa fa-info-circle tooltip-anchor">
                        <div className="tooltip">
                          {t.status === 'unprocessed' && <span>{I18n.t('global.invest.transaction_table.tooltip_unprocessed_text_1')} (<a href="mailto:support@smartcontract.hr">support@smartcontract.hr</a>). {I18n.t('global.invest.transaction_table.tooltip_unprocessed_text_2')}.</span>}
                          {t.status === 'rejected' && <span>{I18n.t('global.invest.transaction_table.tooltip_rejected_text_1')}</span>}
                          {t.status === 'processed_partially' && <span>{I18n.t('global.invest.transaction_table.tooltip_processed_partially_text_1')}</span>}
                        </div>
                      </i>
                    </div>
                  ): (
                    <div className="item-value">{startCase(t.status)}</div>
                  )}
                </div>
                <div className="item transaction-refunds">
                  <div className="mobile-helper">{I18n.t('global.invest.transaction_table.for_refund')} /<br/>{I18n.t('global.invest.transaction_table.refunded')}</div>
                  <div className="item-value">
                    {(t.status === 'processed_partially' && toInteger(t.overpaid_amount_ada) > 0) || t.status === 'rejected' ? (
                      <>
                        {displayPrice(t.status === 'rejected' ? t.initial_ada_amount : t.overpaid_amount_ada, 6, 6)} ADA /<br />
                        {displayPrice(t.refunded_amount_ada, 6, 6)} ADA
                      </>
                    ) : '-'}
                  </div>
                </div>
                <div className="item transaction-actions">
                  <div className="mobile-helper">{I18n.t('global.invest.transaction_table.actions')}:</div>
                  <div className="item-value">
                    {this.getRefundInfo(t.id)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {!isEmpty(transactions) && (
          <div className="table-bottom">
            <div className="total-display">
              <div className="text">{I18n.t('global.invest.transaction_table.total')}</div> {displayPrice(total, 6)} ACI
            </div>
          </div>
        )}
      </div>
    );
  }
}

TransactionTable.propTypes = {
  transactions: PropTypes.array,
  tier: PropTypes.object,
  time: PropTypes.any,
  refunds: PropTypes.array,
};

export default TransactionTable;
