import React from 'react';
import PropTypes from 'prop-types';

class KycProcedureModal extends React.Component {
  constructor() {
    super();
    this.iframeRefEl = React.createRef();
  }

  componentDidMount() {
    this.props.launchWebSdkHandler(`#${this.iframeRefEl.current.id}`);
  }

  render () {
    const { open, toggle } = this.props;

    return (
      <div id="kyc-procedure-modal" style={{ display: open ? 'flex' : 'none' }}>
        <div className="modal-bg" onClick={toggle} />
        <div className="modal-wrapper">
          <div className="modal-header">
            <div className="column">
              <h2>{I18n.t('global.referrals.profile.kyc_procedure')}</h2>
            </div>
            <div className="exit-modal" onClick={toggle}>
              <i className="fa fa-times" />
            </div>
          </div>
          <div className="modal-content">
            <div id="sumsub-websdk-container" ref={this.iframeRefEl} />
          </div>
        </div>
      </div>
    );
  }
}
KycProcedureModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  launchWebSdkHandler: PropTypes.func,
};
export default KycProcedureModal;
