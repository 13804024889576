import React from "react";
import PropTypes from "prop-types";

const mq = window.matchMedia("(max-width: 991px)");
const mqMid = window.matchMedia("(min-width: 992px)") && window.matchMedia("(max-width: 1200px)");

class ProgressSection extends React.Component {
  constructor() {
    super();
    this.state = {
      widthPercentage: 0,
      heightPercentage: 0,
    };
  }

  calculateProgress() {
    const { user, tokensForNextLevel, tokensNeededUntilNextLevel } = this.props;
    const progress = document.querySelector('.progress');
    const bachelorIcon = document.querySelector('.progress-icon.bachelor');
    const magistarIcon = document.querySelector('.progress-icon.magistar');
    const professorIcon = document.querySelector('.progress-icon.professor');
    if (progress) {
      if (user.level === 'freshman') {
        progress.classList.add('to-freshman');
        bachelorIcon.classList.add('alt');
        magistarIcon.classList.add('alt');
        professorIcon.classList.add('alt');
        if (mq.matches) {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 25) + 4.5;
          this.setState({
            widthPercentage: 100,
            heightPercentage: progressPercentage,
          });
        } else if (mqMid.matches) {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 17) + 16;
          this.setState({
            widthPercentage: progressPercentage,
            heightPercentage: 100,
          });
        } else {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 16) + 19;
          this.setState({
            widthPercentage: progressPercentage,
            heightPercentage: 100,
          });
        }
      } else if (user.level === 'bachelor') {
        progress.classList.add('to-bachelor');
        magistarIcon.classList.add('alt');
        professorIcon.classList.add('alt');
        if (mq.matches) {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 30 ) + 17.5;
          this.setState({
            widthPercentage: 100,
            heightPercentage: progressPercentage,
          });
        } else if (mqMid.matches) {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 16) + 42;
          this.setState({
            widthPercentage: progressPercentage,
            heightPercentage: 100,
          });
        } else {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 16) + 42;
          this.setState({
            widthPercentage: progressPercentage,
            heightPercentage: 100,
          });
        }
      } else if (user.level === 'magistar') {
        progress.classList.add('to-magistar');
        professorIcon.classList.add('alt');
        if (mq.matches) {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 45 ) + 36.5;
          this.setState({
            widthPercentage: 100,
            heightPercentage: progressPercentage,
          });
        } else if (mqMid.matches) {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 17) + 66;
          this.setState({
            widthPercentage: progressPercentage,
            heightPercentage: 100,
          });
        } else {
          const progressPercentage = ((tokensForNextLevel/tokensNeededUntilNextLevel) * 16) + 64.5;
          this.setState({
            widthPercentage: progressPercentage,
            heightPercentage: 100,
          });
        }
      } else {
        progress.classList.add('to-professor');
        if (mq.matches) {
          const progressPercentage = ((tokensForNextLevel/50) * 23.5 ) + 76.5;
          this.setState({
            widthPercentage: 100,
            heightPercentage: progressPercentage,
          });
        } else if (mqMid.matches) {
          const progressPercentage = ((tokensForNextLevel/50) * 8.25) + 91.75;
          this.setState({
            widthPercentage: progressPercentage,
            heightPercentage: 100,
          });
        } else {
          const progressPercentage = ((tokensForNextLevel/50) * 12.5) + 87.5;
          this.setState({
            widthPercentage: progressPercentage,
            heightPercentage: 100,
          });
        }
      }
    }
  }

  componentDidMount() {
    this.calculateProgress();
    mq.addEventListener('change', event => {
      this.calculateProgress();
    });
    mqMid.addEventListener('change', event => {
      this.calculateProgress();
    });
  }

  componentWillUnmount() {
    mq.removeEventListener('change', event => {
      this.calculateProgress();
    });
    mqMid.removeEventListener('change', event => {
      this.calculateProgress();
    });
  }

  copyToClipboard(e) {
    e.select();
    document.execCommand('copy');
  }

  render () {
    const {
      user, tokensForNextLevel, tokensNeededUntilNextLevel,
      iluCardanoAddress, deadlinePassed,
    } = this.props;
    const isFreshman = user.level === 'freshman';
    const isBachelor = user.level === 'bachelor';
    const isMagistar = user.level === 'magistar';
    const isProfessor = user.level === 'professor';
    const levelUpUsed = user.instant_level_up_used === true;

    return (
      <div className="progress-section">
        <div className="journey-wrapper">
          <div className="progress-wrapper">
            <div className="progress-bar">
              {isProfessor && user.instant_level_up_level !== 'professor' ? (
                <div className="progress finished"></div>
              ) : (
                <div className="progress" style={{height: this.state.heightPercentage + '%', width: this.state.widthPercentage + '%'}}></div>
              )}
            </div>
            <div className="progress-items-wrapper">
              <div className="progress-item-wrapper">
                <div className="progress-title mobile-hide">
                  {I18n.t('global.journey.roles.freshman')}
                </div>
                <div className="progress-icon freshman">
                  <i className="fa fa-check"></i>
                </div>
                <div className="progress-value mobile-hide">
                  10 ACI
                </div>
                <span className="mobile-helper">
                  <div className="mobile-show">
                    <div className="progress-title">
                      {I18n.t('global.journey.roles.freshman')}
                    </div>
                    <div className="progress-value">
                      10 ACI
                    </div>
                  </div>
                  <div className="progress-description-box">
                    <ul>
                      <li>{I18n.t('global.journey.role_descriptions.item_1_1')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_1_2')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_1_3')}</li>
                    </ul>
                  </div>
                  {deadlinePassed === false && (
                    <>
                      {!levelUpUsed && iluCardanoAddress && isFreshman && (
                        <div className="level-up-item">
                          <div className="level-up-tag">
                            {I18n.t('global.journey.instant_level_up')}
                          </div>
                          <div className="level-up-header">
                            {I18n.t('global.journey.to_bachelor')}
                          </div>
                          <div className="level-up-subheader">
                            {I18n.t('global.journey.level_ups.one_level_up')}<br />
                            {I18n.t('global.journey.level_ups.one_level_up_2')}
                          </div>
                          <div className="level-up-description">
                            <b>{I18n.t('global.journey.cost')} 10 ADA</b><br />
                            {I18n.t('global.journey.send_ada_message_1_1')}
                          </div>
                          <div className="level-up-input-field">
                            <input ref={(val) => this.levelup1 = val} value={iluCardanoAddress} readOnly />
                          </div>
                          <div className="copy-to-clipboard-wrapper">
                            <button className="copy-levelup" onClick={this.copyToClipboard.bind(this, this.levelup1)}>{I18n.t('global.journey.copy_to_clipboard')}</button>
                          </div>
                          <div className="level-up-description">
                            {I18n.t('global.journey.send_ada_message_1_2')}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </span>
              </div>
              <div className="progress-item-wrapper">
                <div className="progress-title mobile-hide">
                  {I18n.t('global.journey.roles.bachelor')}
                </div>
                <div className="progress-icon bachelor">
                  <i className="fa fa-check"></i>
                </div>
                <div className="progress-value mobile-hide">
                  100 ACI
                </div>
                <span className="mobile-helper">
                  <div className="mobile-show">
                    <div className="progress-title">
                      {I18n.t('global.journey.roles.bachelor')}
                    </div>
                    <div className="progress-value">
                      100 ACI
                    </div>
                  </div>
                  <div className="progress-description-box">
                    <ul>
                      <li>{I18n.t('global.journey.role_descriptions.item_2_1')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_2_2')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_2_3')}</li>
                    </ul>
                  </div>
                  {deadlinePassed === false && (
                    <>
                      {!levelUpUsed && iluCardanoAddress && (isFreshman || isBachelor) && (
                        <div className="level-up-item">
                          <div className="level-up-tag">
                            {I18n.t('global.journey.instant_level_up')}
                          </div>
                          <div className="level-up-header">
                            {I18n.t('global.journey.to_magistar')}
                          </div>
                          <div className="level-up-subheader">
                            {I18n.t('global.journey.level_ups.two_levels_up')}<br />
                            {I18n.t('global.journey.level_ups.two_levels_up_2')}
                          </div>
                          <div className="level-up-description">
                            <b>{I18n.t('global.journey.cost')} 25 ADA</b><br />
                            {I18n.t('global.journey.send_ada_message_2_1')}
                          </div>
                          <div className="level-up-input-field">
                            <input ref={(val) => this.levelup2 = val} value={iluCardanoAddress} readOnly />
                          </div>
                          <div className="copy-to-clipboard-wrapper">
                            <button className="copy-levelup" onClick={this.copyToClipboard.bind(this, this.levelup2)}>{I18n.t('global.journey.copy_to_clipboard')}</button>
                          </div>
                          <div className="level-up-description">
                            {I18n.t('global.journey.send_ada_message_2_2')}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </span>
              </div>
              <div className="progress-item-wrapper">
                <div className="progress-title mobile-hide">
                  {I18n.t('global.journey.roles.magistar')}
                </div>
                <div className="progress-icon magistar">
                  <i className="fa fa-check"></i>
                </div>
                <div className="progress-value mobile-hide">
                  250 ACI
                </div>
                <span className="mobile-helper">
                  <div className="mobile-show">
                    <div className="progress-title">
                      {I18n.t('global.journey.roles.magistar')}
                    </div>
                    <div className="progress-value">
                      250 ACI
                    </div>
                  </div>
                  <div className="progress-description-box">
                    <ul>
                      <li>{I18n.t('global.journey.role_descriptions.item_3_1')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_3_2')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_3_3')}</li>
                    </ul>
                  </div>
                  {deadlinePassed === false && (
                    <>
                      {!levelUpUsed && iluCardanoAddress && (isFreshman || isBachelor || isMagistar) && (
                        <div className="level-up-item highlight">
                          <div className="level-up-tag">
                            {I18n.t('global.journey.instant_level_up')}
                          </div>
                          <div className="level-up-header">
                            {I18n.t('global.journey.to_professor')}
                          </div>
                          <div className="level-up-subheader">
                            {I18n.t('global.journey.level_ups.instant_professor')}
                          </div>
                          <div className="level-up-description">
                            <b>{I18n.t('global.journey.cost')} 50 ADA</b><br />
                            {I18n.t('global.journey.send_ada_message_3_1')}
                          </div>
                          <div className="level-up-input-field">
                            <input ref={(val) => this.levelup3 = val} value={iluCardanoAddress} readOnly />
                          </div>
                          <div className="copy-to-clipboard-wrapper">
                            <button className="copy-levelup" onClick={this.copyToClipboard.bind(this, this.levelup3)}>{I18n.t('global.journey.copy_to_clipboard')}</button>
                          </div>
                          <div className="level-up-description">
                            {I18n.t('global.journey.send_ada_message_3_2')}<br /><br />
                            {I18n.t('global.journey.send_ada_message_3_3')}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </span>
              </div>
              <div className="progress-item-wrapper">
                <div className="progress-title mobile-hide">
                  {I18n.t('global.journey.roles.professor')}
                </div>
                <div className="progress-icon professor">
                  <i className="fa fa-star"></i>
                </div>
                <div className="progress-value mobile-hide">
                  500 ACI
                </div>
                <span className="mobile-helper">
                  <div className="mobile-show">
                    <div className="progress-title">
                      {I18n.t('global.journey.roles.professor')}
                    </div>
                    <div className="progress-value">
                      500 ACI
                    </div>
                  </div>
                  <div className="progress-description-box">
                    <ul>
                      <li>{I18n.t('global.journey.role_descriptions.item_4_2')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_4_3')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_4_4')}</li>
                      <li>{I18n.t('global.journey.role_descriptions.item_4_5')}</li>
                    </ul>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProgressSection.propTypes = {
  user: PropTypes.object,
  tokensForNextLevel: PropTypes.any,
  tokensNeededUntilNextLevel: PropTypes.number,
  iluCardanoAddress: PropTypes.string,
};
export default ProgressSection;
