import React from 'react';
import PropTypes from 'prop-types';
import { displayPrice } from '../utils';
import editIcon from '../../assets/images/icons/edit-solid.svg';

class UserInfoBox extends React.Component {
  render () {
    const { user } = this.props;

    return (
      <div className="user-info-box">
        <div className="user-info-wrapper">
          <div className="user-info">
            <div className="user-name">
              {user.first_name} {user.last_name}
            </div>
            <div className="user-role">
              <span>{I18n.t('global.navigation.roles.referral_status')}:</span> {user.level}
            </div>
            <a href="/users/edit" className="edit-profile-button">{I18n.t('global.referrals.profile.edit_profile_button')}
              <img src={editIcon} alt="Edit icon" />
            </a>
          </div>

          <div className="divider" />

          <div className="column">
            <div className="aci-balance-wrapper">
              <p className="aci-balance-text">
                {I18n.t('global.navigation.balance.balance_text')}
              </p>
              <div className="aci-balance">
                {displayPrice(user.total_aci_gained, 6)} ACI
              </div>
            </div>

            <div className="cardano-address-wrapper">
              <p className="cardano-address-text">
                {I18n.t('global.referrals.profile.cardano_address')}:
              </p>
              <div className="cardano-address">
                {user.return_address !== null ? (
                  user.return_address
                ) : (
                  <span>{I18n.t('global.referrals.profile.cardano_address_provide_cta')}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserInfoBox.propTypes = {
  user: PropTypes.object,
};
export default UserInfoBox;
