$(document).ready(function () {
  $(".password-visibility-toggle").click(function () {
    $(".visibility-toggle-icon").toggleClass("fa-eye fa-eye-slash");
    const input = $("#password-field");
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  /* If checkbox state is kept on submit validation fails */
  var boll = $("#terms").prop("checked");
  if (boll !== undefined && boll === false) {
    $(".actions input[type]:submit").addClass("disabled");
  } else {
    $(".actions input[type]:submit").removeClass("disabled");
  }

  $("#terms").click(function () {
    boll = $(this).prop("checked");
    if (boll === false) {
      $(".actions input[type]:submit").addClass("disabled");
    } else {
      $(".actions input[type]:submit").removeClass("disabled");
    }
  });

  $(".register-form input[type]:submit").click(function () {
    boll = $("#terms").prop("checked");
    if (boll === false) {
      event.preventDefault();
      $(".terms-error").css("display", "block");
    } else {
      $(".terms-error").css("display", "none");
    }
  });
});
