function checkVerificationStatusInDatabase(targetedStatus, attemptsNumber, interval) {
  let databaseStatusUpdated = false;

  return new Promise(resolve => {
    setTimeout(() => {
      $.ajax('/check_kyc_verification_status', {
        method: 'GET',
        dataType: 'json',
        beforeSend(xhr) {
          return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        }
      })
      .done(response => {
        if (response.kyc_verification_status == targetedStatus) {
          databaseStatusUpdated = true;
        }
        attemptsNumber--;
        if (databaseStatusUpdated) {
          resolve(targetedStatus);
        } else {
          if (attemptsNumber > 0) {
            resolve(checkVerificationStatusInDatabase(targetedStatus, attemptsNumber, interval));
          } else {
            resolve(null);
          }
        }
      })
    }, interval);
  });
}


pollForVerificationStatusOnServer = (targetedStatus) => {
  // We will ask server for verification status 5 times, with 2-seconds interval between checks
  let attemptsNumber = 10;
  let interval = 1500;
  return new Promise(resolve => {
    checkVerificationStatusInDatabase(targetedStatus, attemptsNumber, interval)
    .then(response => {
      resolve(response);
    })
  });
}

// @param accessToken - access token that you generated on the backend in Step 2
// @param applicantEmail - applicant email (not required)
// @param applicantPhone - applicant phone, if available (not required)
// @param customI18nMessages - customized locale messages for current session (not required)
launchWebSdk = (accessToken, applicantEmail, applicantPhone, customI18nMessages) => {
  let snsWebSdkInstance = snsWebSdk.init(
    accessToken,
    // If access-token (session) has expired, this callback (which must return Promise) will get us a new (valid) one.
    () => getNewAccessToken()
  )
  .withConf({
    lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
    email: applicantEmail,
    phone: applicantPhone,
    i18n: customI18nMessages, //JSON of custom SDK Translations
    uiConf: {
      customCss: "https://url.com/styles.css"
      // URL to css file in case you need change it dynamically from the code
      // the similar setting at Customizations tab will rewrite customCss
      // you may also use to pass string with plain styles `customCssStr:`
    },
  })
  .withOptions({ addViewportTag: false, adaptIframeHeight: true})
  .on('idCheck.applicantStatus', (payload) => {
    let targetedStatus = payload.reviewStatus;
    let reviewResult = payload.reviewResult;
    if (targetedStatus == 'pending') {
      if ($('#user_kyc_status').val() != 'pending') {
        pollForVerificationStatusOnServer('pending')
        .then(response => {
          if (response && response === 'pending') {
            $("#go-through-kyc").css("display", "none");
            $("#kyc-failed-retry").css("display", "none");
            $("#kyc-failed-retry-static").css("display", "none");
            $("#kyc-failed-final-static").css("display", "none");
            $("#kyc-pending").css("display", "flex");
            $('#user_kyc_status').val('pending');
          }
        })
      }
    } else if (targetedStatus == 'completed' && reviewResult.reviewAnswer == 'GREEN') {
      if ($('#user_kyc_status').val() != 'verified') {
        pollForVerificationStatusOnServer('verified')
        .then(response => {
          if (response && response === 'verified') {
            $("#go-through-kyc").css("display", "none");
            $("#kyc-pending").css("display", "none");
            $('#kyc-complete').css("display", "flex");
            $('#user_kyc_status').val('verified');
          }
        })
      }
    } else if (targetedStatus == 'completed' && reviewResult.reviewAnswer == 'RED') {
      if ($('#user_kyc_status').val() != 'rejected') {
        pollForVerificationStatusOnServer('rejected')
        .then(response => {
          if (response && response === 'rejected') {
            if (reviewResult.reviewRejectType == 'FINAL') {
              $("#go-through-kyc").css("display", "none");
              $("#kyc-pending").css("display", "none");
              $('#kyc-failed').css("display", "flex");
            } else {
              $("#go-through-kyc").css("display", "none");
              $("#kyc-pending").css("display", "none");
              $('#kyc-failed-retry').css("display", "flex");
            }
            $('#user_kyc_status').val('rejected');
          }
        })
      }
    }
  })

  .build();

  // you are ready to go:
  // just launch the WebSDK by providing the container element for it
  snsWebSdkInstance.launch('#sumsub-websdk-container')
}

getNewAccessToken = () => {
  return new Promise(resolve => {
    getAccessToken()
    .done(response => {
      resolve(response.token);
    })
  });
}

getAccessToken = () => (
  $.ajax('/generate_access_token', {
    method: 'GET',
    dataType: 'json',
    // data: { time_period: timePeriod },
    beforeSend(xhr) {
      return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    }
  })
)


$(document).ready(() => {
  // if (typeof($('.user-profile-wrapper')[0]) != "undefined") {
  //   getAccessToken()
  //   .done(response => {
  //     launchWebSdk(response.token);
  //   })
  //   .fail(e => {
  //     console.log(`Error: ${e.responseJSON.error.description}`);
  //   });

  //   const userKycStatus = $('#user_kyc_status').val();
  //   if (userKycStatus === 'pending') {
  //     $("#go-through-kyc").css("display", "none");
  //     $("#kyc-pending").css("display", "flex");
  //   }

  //   if (userKycStatus === 'verified') {
  //     $("#go-through-kyc").css("display", "none");

  //     $("#kyc-pending").css("display", "none");
  //     $('#kyc-complete').css("display", "flex");
  //   }

  //   if (userKycStatus === 'rejected') {
  //     $("#go-through-kyc").css("display", "none");

  //     $("#kyc-pending").css("display", "none");
  //   }

  //   $(".open-modal").on("click", function () {
  //     var caller = $(this).attr("id");

  //     if (caller == "go-through-kyc") {
  //       $("#kyc-procedure-modal").css("display", "block");
  //     } else if (caller == "kyc-pending") {
  //       $("#kyc-procedure-modal").css("display", "block");
  //     } else if (caller == "kyc-failed-retry") {
  //       $("#kyc-procedure-modal").css("display", "block");
  //     } else if (caller == "kyc-failed-retry-static") {
  //       $("#kyc-procedure-modal").css("display", "block");
  //     }

  //     $("#modal").css("display", "flex");
  //   });
  // }
});
