import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { displayPrice } from '../utils';

class TokensBreakdown extends React.Component {
  render () {
    const {
      user, aciGainedByTokenSale, aciGainedByReferrals,
      aciGainedByVouchers, aciGainedByInstantLevelUp, totalAciGained,
    } = this.props;

    const enLocale = locale === 'en';

    return (
      <div className="tokens-breakdown">
        <div className="tokens-breakdown-header">
          {I18n.t('global.tokens_breakdown.title')}
        </div>
        <div className="tokens-breakdown-table">
          <div className="row">
            <div className="label">
              {I18n.t('global.tokens_breakdown.referrals')}
            </div>
            <div className="info">{displayPrice(aciGainedByReferrals)} ACI</div>
          </div>
          <div className="row">
            <div className="label">
              {I18n.t('global.tokens_breakdown.level_up')}
            </div>
            <div className="info">{displayPrice(aciGainedByInstantLevelUp)} ACI</div>
          </div>
          <div className="row">
            <div className="label">
              {I18n.t('global.tokens_breakdown.voucher')}
            </div>
            <div className="info">{displayPrice(aciGainedByVouchers)} ACI</div>
          </div>
          <div className="row">
            <div className="label">
              {I18n.t('global.tokens_breakdown.token_sale')}
            </div>
            <div className="info">{displayPrice(aciGainedByTokenSale, 6)} ACI</div>
          </div>
        </div>
        <div className="aci-total-wrapper">
          <div className="label">
            {I18n.t('global.tokens_breakdown.total')}
          </div>
          <div className="info">{displayPrice(totalAciGained, 6)} ACI</div>
        </div>
        <div className="breakdown-invest-cta">
          <div className="text">
            {I18n.t('global.tokens_breakdown.cta')}
          </div>
          <div className="invest-button-wrapper">
            <Link to={enLocale ? '/invest' : `/${locale}/invest`} className="invest-button">
              {I18n.t('global.tokens_breakdown.cta_link')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

TokensBreakdown.propTypes = {
  user: PropTypes.object,
  aciGainedByTokenSale: PropTypes.number,
  aciGainedByReferrals: PropTypes.number,
  aciGainedByVouchers: PropTypes.number,
  aciGainedByInstantLevelUp: PropTypes.number,
  totalAciGained: PropTypes.number,
};
export default TokensBreakdown;
