import React from "react";
import PropTypes from "prop-types";
import whitepaper_en from "../../assets/images/pdf/whitepaper-en.pdf";
import whitepaper_hr from "../../assets/images/pdf/whitepaper-hr.pdf";
import whitepaper_de from "../../assets/images/pdf/whitepaper-de.pdf";
import whitepaper_tr from "../../assets/images/pdf/whitepaper-tr.pdf";
import whitepaper_es from "../../assets/images/pdf/whitepaper-es.pdf";

class FaqModal extends React.Component {
  handlePdf1 = () => {
    if(locale === 'hr') return <a href={whitepaper_hr} target='_blank' download>bijeli papir</a>
    if(locale === 'de') return <a href={whitepaper_de} target='_blank' download>Whitepaper</a>
    if(locale === 'tr') return <a href={whitepaper_tr} target='_blank' download>Whitepaper</a>
    if(locale === 'es') return <a href={whitepaper_es} target='_blank' download>Whitepaper</a>
    return <a href={whitepaper_en} target='_blank' download>Whitepaper</a>
  };

  handlePdf2 = () => {
    if(locale === 'hr') return <a href={whitepaper_hr} target='_blank' download>ovdje</a>
    if(locale === 'de') return <a href={whitepaper_de} target='_blank' download>here</a>
    if(locale === 'tr') return <a href={whitepaper_tr} target='_blank' download>here</a>
    if(locale === 'es') return <a href={whitepaper_es} target='_blank' download>here</a>
    return <a href={whitepaper_en} target='_blank' download>here</a>
  };

  render () {
    const { open, toggle } = this.props;
    return (
      <>
        {open && (
          <div id="faq-modal">
            <div className="modal-bg" onClick={toggle}></div>
            <div className="modal-wrapper">
              <div className="modal-header">
                <h2>{I18n.t('global.footer.links.faq')}</h2>
                <div className="exit-modal" onClick={toggle}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
              <div className="modal-content">
                <h3>{I18n.t('global.faq.title_1')}</h3>
                <p>{I18n.t('global.faq.content_1.text')}</p>
                <h3>{I18n.t('global.faq.title_2')}</h3>
                <p>{I18n.t('global.faq.content_2.text')}</p>
                <h3>{I18n.t('global.faq.title_3')}</h3>
                <p>
                  {I18n.t('global.faq.content_3.text')}
                  {" "}
                  {this.handlePdf1()}
                  .
                </p>
                <h3>{I18n.t('global.faq.title_4')}</h3>
                <p>{I18n.t('global.faq.content_4.text')}</p>
                <h3>{I18n.t('global.faq.title_5')}</h3>
                <p>{I18n.t('global.faq.content_5.text_1')}</p>
                <p>{I18n.t('global.faq.content_5.text_2')}</p>
                <p>{I18n.t('global.faq.content_5.text_3')}</p>
                <h3>{I18n.t('global.faq.title_6')}</h3>
                <p>{I18n.t('global.faq.content_6.text')}</p>
                <h3>{I18n.t('global.faq.title_7')}</h3>
                <p>{I18n.t('global.faq.content_7.text')}</p>
                <div className="modal-table">
                  <div className="modal-row row-header row-2">
                    <span className="column-title">{I18n.t('global.faq.content_7.table.column_1.title')}</span>
                    <span className="column-title">{I18n.t('global.faq.content_7.table.column_2.title')}</span>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_1.cell_1')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_2.cell_1')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_1.cell_2')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_2.cell_2')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_1.cell_3')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_2.cell_3')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_1.cell_4')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_2.cell_4')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-2">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_1.cell_5')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_7.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_7.table.column_2.cell_5')}</span>
                    </div>
                  </div>

                </div>
                <h3>{I18n.t('global.faq.title_8')}</h3>
                <p>{I18n.t('global.faq.content_8.text')}</p>
                <h3>{I18n.t('global.faq.title_9')}</h3>
                <p>{I18n.t('global.faq.content_9.text')}</p>
                <h3>{I18n.t('global.faq.title_10')}</h3>
                <p>{I18n.t('global.faq.content_10.text')}</p>
                <h3>{I18n.t('global.faq.title_11')}</h3>
                <p>
                  {I18n.t('global.faq.content_11.text')}
                  {" "}
                  {locale === "hr" ? (
                    <a href="/hr/referrals">programu preporuke</a>
                  ) : (
                    <a href="/referrals">here</a>
                  )}
                  .
                </p>
                <h3>{I18n.t('global.faq.title_12')}</h3>
                <p>{I18n.t('global.faq.content_12.text')}</p>
                <h3>{I18n.t('global.faq.title_13')}</h3>
                <p>{I18n.t('global.faq.content_13.text')}</p>
                <h3>{I18n.t('global.faq.title_14')}</h3>
                <p>
                  {I18n.t('global.faq.content_14.text_1')}
                  {" "}
                  {this.handlePdf2()}
                  .
                  {" "}
                  {I18n.t('global.faq.content_14.text_2')}
                </p>
                <h3>{I18n.t('global.faq.title_15')}</h3>
                <p>{I18n.t('global.faq.content_15.text')}</p>
                <h3>{I18n.t('global.faq.title_16')}</h3>
                <p>{I18n.t('global.faq.content_16.text')}</p>
                <h3>{I18n.t('global.faq.title_17')}</h3>
                <p>{I18n.t('global.faq.content_17.text')}</p>
                <h3>{I18n.t('global.faq.title_18')}</h3>
                <p>{I18n.t('global.faq.content_18.text')}</p>
                <h3>{I18n.t('global.faq.title_19')}</h3>
                <p>{I18n.t('global.faq.content_19.text')}</p>
                <div className="modal-table">
                  <div className="modal-row row-header row-3">
                    <span className="column-title">{I18n.t('global.faq.content_19.table.column_1.title')}</span>
                    <span className="column-title">{I18n.t('global.faq.content_19.table.column_2.title')}</span>
                    <span className="column-title">{I18n.t('global.faq.content_19.table.column_3.title')}</span>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_1.cell_1')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_2.cell_1')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_3.cell_1')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_1.cell_2')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_2.cell_2')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_3.cell_2')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_1.cell_3')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_2.cell_3')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_3.cell_3')}</span>
                    </div>
                  </div>
                  <div className="modal-row row-3">
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_1.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_1.cell_4')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_2.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_2.cell_4')}</span>
                    </div>
                    <div className="column-cell">
                      <span className="mobile-cell-title">
                        {I18n.t('global.faq.content_19.table.column_3.title')}
                      </span>
                      <span>{I18n.t('global.faq.content_19.table.column_3.cell_4')}</span>
                    </div>
                  </div>

                </div>
                <h3>{I18n.t('global.faq.title_20')}</h3>
                <p>{I18n.t('global.faq.content_20.text')}</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
FaqModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func
};
export default FaqModal;