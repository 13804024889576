import values from 'lodash/values';
import { DEFAULT_POLL_TIME_MILISECONDS } from '.';

export default class SimplePoller {
  timerIds = {};

  startPoll(path, time, callback) {
    this.clearPoll(path);
    jQuery.getJSON(path, (data) => {
      callback(data);
      this.timerIds[path] = setTimeout(() => {
        this.startPoll(path, time, callback);
      }, time);
    });
  }

  clearPoll(path) {
    const timerId = this.timerIds[path];
    clearTimeout(timerId);
    this.timerIds[path] = null;
  }

  clearAll() {
    values(this.timerIds).forEach(val => {
      clearTimeout(val);
    });
    this.timerIds = {};
  }

  startDefaultPoller(handler) {
    this.startPoll('/poll/user_profile', DEFAULT_POLL_TIME_MILISECONDS, handler);
  }
}
