/* eslint-disable */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import 'bootstrap-datepicker';
import "channels"
import "chartkick/chart.js"
import "styles/application.css"

Rails.start()
ActiveStorage.start()

require("@rails/actiontext")

var Trix = require("trix")
Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
}
Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
}

addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" class="trix-button  " data-trix-attribute="heading2" title="h2" tabindex="-1">H2</button>'
  event.target.toolbarElement.
  querySelector(".trix-button-group").
  insertAdjacentHTML("beforeend", buttonHTML)
})

addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" class="trix-button  " data-trix-attribute="heading3" title="h3" tabindex="-1">H3</button>'
  event.target.toolbarElement.
  querySelector(".trix-button-group").
  insertAdjacentHTML("beforeend", buttonHTML)
})
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import I18n from 'i18n-js'
window.I18n = I18n

// private sale is not tracked in the DB so we need this object
// to insert data to views
// used as one of user_transactions
window.PrivateSaleData = {
  total_tokens_allotted: 15000000, // 15_000_000
  total_aci_tokens_accepted: 15000000, // 15_000_000
};
