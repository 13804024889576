import Tick from "@pqina/flip";

$(document).ready(function () {
  if ($('.cta-wrapper').length) {
    function handleTickInit(tick) {
      // Set labels to different languages
      var locale_en = {
        DAY_PLURAL: "Days",
        DAY_SINGULAR: "Days",
        HOUR_PLURAL: "Hours",
        HOUR_SINGULAR: "Hours",
        MINUTE_PLURAL: "Minutes",
        MINUTE_SINGULAR: "Minutes",
        SECOND_PLURAL: "Seconds",
        SECOND_SINGULAR: "Seconds",
      };

      var locale_hr = {
        DAY_PLURAL: "Dana",
        DAY_SINGULAR: "Dana",
        HOUR_PLURAL: "Sati",
        HOUR_SINGULAR: "Sati",
        MINUTE_PLURAL: "Minuta",
        MINUTE_SINGULAR: "Minuta",
        SECOND_PLURAL: "Sekundi",
        SECOND_SINGULAR: "Sekundi",
      };

      if (locale == "en") {
        for (var key in locale_en) {
          tick.setConstant(key, locale_en[key]);
        }
      } else if (locale == "hr") {
        for (var key in locale_hr) {
          tick.setConstant(key, locale_hr[key]);
        }
      }

      // format of due date is ISO8601
      // create the countdown counter
      var counter = Tick.count.down("2021-11-15T07:00:00.000Z");

      counter.onupdate = function (value) {
        tick.value = value;
      };

      counter.onended = function () {
        $(".tick").css("display", "none");
        $(".cta-title").css("display", "none");
        $(".cta-title-finish").css("display", "block");
      };
    }

    $(".tick").each(function () {
      Tick.DOM.create(this, { didInit: handleTickInit });
    });
  }
});
