import React from "react";

class DelayNotice extends React.Component {
  render () {
    return (
      <div className="delay-notice">
        <span>
          {I18n.t('global.referrals.delay.delay_text')}
        </span>
      </div>
    );
  }
}

export default DelayNotice;