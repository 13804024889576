import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import map from "lodash/map";
import logoIconColor from '../../assets/images/logo/aci_logo_color.png';
import flagUK from '../../assets/images/flags/united_kingdom_flag.png';
import flagCroatia from '../../assets/images/flags/croatia_flag.png';
import flagGermany from '../../assets/images/flags/germany_flag.png';
import flagSpain from '../../assets/images/flags/spain_flag.png';
import flagTurkey from '../../assets/images/flags/turkey_flag.png';
import { isOngoing } from "../utils";

class Navigation extends React.Component {
  constructor() {
    super();
    this.state = {
      currentLocale: locale,
      languageMenuOpen: false,
      mobileMenuOpen: false,
      mobileLanguageMenuOpen: false,
    };

    this.dropdownRef = React.createRef();
  }

  toggleLanguageMenu = () => {
    this.setState({ languageMenuOpen: !this.state.languageMenuOpen });
  };

  handleOutsideClick = (e) => {
    const isDescendant = this.dropdownRef && this.dropdownRef.contains(e.target);
    if (this.state.languageMenuOpen && !isDescendant) {
      this.setState({ languageMenuOpen: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  toggleMobileMenu = () => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  };

  toggleMobileLanguageMenu = () => {
    this.setState({ mobileLanguageMenuOpen: !this.state.mobileLanguageMenuOpen });
  };

  render () {
    I18n.locale = this.state.currentLocale;
    const { languageMenuOpen, mobileMenuOpen, mobileLanguageMenuOpen } = this.state;
    const { registeredUser } = this.props;
    const enLocale = locale === 'en';

    const currentUrl = window.location.pathname;
    let path;
    if (currentUrl.includes("/", 2)) {
      path = '/' + currentUrl.split("/").pop();
    } else {
      path = currentUrl;
    };

    let chosenLocale;
    if (locale === 'en') {
      chosenLocale = flagUK;
    } else if (locale === 'hr') {
      chosenLocale = flagCroatia;
    }

    return (
      <div className="react-navigation-wrapper">
        <div className="react-navigation">
          <div className="react-nav-items-wrapper">
            <div className="logo">
              <a href={enLocale ? '/' : `/${locale}/`}>
                <img src={logoIconColor} alt="Blockademia document verification system Logo" />
              </a>
            </div>
            <div className="react-links">
              <Link to={enLocale ? '/dashboard' : `/${locale}/dashboard`} className="nav-link">
                {I18n.t('global.navigation.links.link_dashboard')}
              </Link>
            </div>
            <div className="react-additional-links">
              <div className="react-language-menu" onClick={this.toggleLanguageMenu.bind(this)} ref={(node) => { this.dropdownRef = node; }}>
                <img src={chosenLocale} className="country" alt="UK flag" />
                <div className="menu-icon">
                  <i className="fa fa-chevron-down"></i>
                </div>
                {languageMenuOpen && (
                  <div className="react-language-menu-options">
                    <a className="option" href={path}>
                      <img src={flagUK} alt="UK flag" />
                    </a>
                    <a className="option" href={`/hr${path}`}>
                      <img src={flagCroatia} alt="Croatia flag" />
                    </a>
                  </div>
                )}
              </div>
              {registeredUser === false ? (
                <a className="react-login-button" href={enLocale ? '/users/sign_in' : `/${locale}/users/sign_in`}>
                  {I18n.t('global.navigation.additional_links.login')}
                </a>
              ) : (
                <>
                  <Link to={enLocale ? '/profile' : `/${locale}/profile`} className="react-my-profile-button">
                    {I18n.t('global.navigation.additional_links.profile')}
                  </Link>
                  <a className="react-logout-button" rel="nofollow" data-method="delete" href={enLocale ? '/users/sign_out' : `/${locale}/users/sign_out`}>
                    {I18n.t('global.navigation.additional_links.logout')}
                  </a>
                </>
              )}
            </div>

            <div className="react-mobile-menu-button" onClick={this.toggleMobileMenu.bind(this)}>
              <i className="fa fa-bars"></i>
            </div>
            {mobileMenuOpen && (
              <div className="react-mobile-menu">
                <div className="react-close-menu-button" onClick={this.toggleMobileMenu.bind(this)}>
                  <i className="fa fa-times"></i>
                </div>
                <div className="react-links">
                  <Link to={enLocale ? '/dashboard' : `/${locale}/dashboard`} className="nav-link" onClick={this.toggleMobileMenu.bind(this)}>
                    {I18n.t('global.navigation.links.link_dashboard')}
                  </Link>
                </div>
                <div className="react-additional-links">
                  {registeredUser === false ? (
                    <a className="react-login-button" href={enLocale ? '/users/sign_in' : `/${locale}/users/sign_in`}>
                      {I18n.t('global.navigation.additional_links.login')}
                    </a>
                  ) : (
                    <>
                      <Link to={enLocale ? '/profile' : `/${locale}/profile`} className="react-my-profile-button" onClick={this.toggleMobileMenu.bind(this)}>
                        {I18n.t('global.navigation.additional_links.profile')}
                      </Link>
                      <a className="react-logout-button" rel="nofollow" data-method="delete" href={enLocale ? '/users/sign_out' : `/${locale}/users/sign_out`}>
                        {I18n.t('global.navigation.additional_links.logout')}
                      </a>
                    </>
                  )}
                  <div className="react-language-menu" onClick={this.toggleMobileLanguageMenu.bind(this)}>
                    <img src={chosenLocale} className="country" alt="UK flag" />
                    <div className="menu-icon">
                      <i className="fa fa-chevron-down"></i>
                    </div>
                    {mobileLanguageMenuOpen && (
                      <div className="react-language-menu-options">
                        <a className="option" href={path}>
                          <img src={flagUK} alt="UK flag" />
                        </a>
                        <a className="option" href={`/hr${path}`}>
                          <img src={flagCroatia} alt="Croatia flag" />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  registeredUser: PropTypes.bool,
  tiers: PropTypes.array,
};
export default Navigation;