import React from "react";

class LevelUpNotice extends React.Component {
  render () {
    return (
      <div className="level-up-notice">
        {I18n.t('global.referrals.voucher.voucher_text')}
      </div>
    );
  }
}

export default LevelUpNotice;