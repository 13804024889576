// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context(".", true, /_channel\.js$/);
channels.keys().forEach(channels);

import Navigation from "./navigation";
import Login from "./login";
import Admin from "./admin";
import AdminDashboard from "./admin_dashboard";
import Blog from "./blog";
// import Timer from "./timer";
import TimerFlip from "./timer_flip";
import DatePicker from "./datepicker";
import Modal from "./modal";
import ToTop from "./to_top";
import Kyc from "./kyc";
