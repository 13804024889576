import React from "react";
import PropTypes from "prop-types";

class TokenSaleRulesModal extends React.Component {
  render () {
    const { open, toggle } = this.props;
    return (
      <>
        {open && (
          <div id="token-sale-rules-modal">
            <div className="modal-bg" onClick={toggle}></div>
            <div className="modal-wrapper">
              <div className="modal-header">
                <div className="column">
                  <h2>{I18n.t('global.token_sale.invest.rules_word')}</h2>
                  <div className="modal-subheader">
                    {I18n.t('global.token_sale.rules.rules_top_notice')}
                  </div>
                </div>
                <div className="exit-modal" onClick={toggle}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
              <div className="modal-content">
                <ul>
                  <li>{I18n.t('global.token_sale.rules.item_1')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_2')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_3')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_4')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_5')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_6')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_7')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_8')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_9')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_10')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_11')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_12')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_13')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_14')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_15')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_16')}</li>
                  <li>{I18n.t('global.token_sale.rules.item_17')}</li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
TokenSaleRulesModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func
};
export default TokenSaleRulesModal;