import React from 'react';
import PropTypes from 'prop-types';
import camelcaseKeys from 'camelcase-keys-deep';
import Slider from 'rc-slider';
import toInteger from 'lodash/toInteger';
import toFinite from 'lodash/toFinite';
import {
  displayDate, displaySpecialInvestmentMargins,
  displayPrice, displayMinimumInvestmentMargins,
  displayMaximumInvestmentMargins,
  displaySpecialMinimumInvestmentMargins,
} from '../../utils';

class TokenSaleInvestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eurValue: this.props.user.level === 'professor' && this.props.tier.order === 3 ? 100 : toInteger(props.tier.min_investment),
      adaValue: 0,
      aciValue: 0,
      oneAciToEuro: toFinite(props.tier.aci_price),
      userData: camelcaseKeys(props.user),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.recalculate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.oneAdaToEuro !== prevProps.oneAdaToEuro) { // exchange rate changed
      this.recalculate();
    }
  }

  recalculate = () => {
    this.setState({
      adaValue: (this.state.eurValue / this.props.oneAdaToEuro).toFixed(6),
      aciValue: (this.state.eurValue / this.state.oneAciToEuro).toFixed(6),
    });
  };

  handleSliderChange = (value) => {
    this.setState({
      eurValue: value,
      adaValue: (value / this.props.oneAdaToEuro).toFixed(6),
      aciValue: (value / this.state.oneAciToEuro).toFixed(6),
    });
  };

  handleChange = (event) => {
    const { oneAdaToEuro } = this.props;
    const { oneAciToEuro } = this.state;

    if (event.target.name === 'eurValue') {
      this.setState({
        eurValue: event.target.value,
        adaValue: (event.target.value / oneAdaToEuro).toFixed(6),
        aciValue: (event.target.value / oneAciToEuro).toFixed(6),
      });
    } else if (event.target.name === 'aciValue') {
      this.setState({
        aciValue: event.target.value,
        adaValue: ((event.target.value / oneAdaToEuro) * oneAciToEuro).toFixed(6),
        eurValue: (event.target.value * oneAciToEuro).toFixed(6),
      });
    } else if (event.target.name === 'adaValue') {
      this.setState({
        adaValue: event.target.value,
        aciValue: ((event.target.value * oneAdaToEuro) / oneAciToEuro).toFixed(6),
        eurValue: (event.target.value * oneAdaToEuro).toFixed(6),
      });
    }
  };

  copyToClipboard = () => {
    const value = document.getElementById('investAddress');
    value.select();
    document.execCommand('copy');
  };

  tierName = (order) => {
    switch (order) {
      case 2:
        return I18n.t('global.token_sale.invest.early_sale_t1');
      case 3:
        return I18n.t('global.token_sale.invest.early_sale_t2');
      case 4:
        return I18n.t('global.token_sale.invest.community_sale');
      default:
        return '';
    }
  };

  render () {
    const { user, open, toggle, tier } = this.props;
    const { eurValue, adaValue, aciValue } = this.state;
    const { tierCardanoAddresses } = this.state.userData;

    if (!open) {
      return null;
    }

    let cardanoAddressForCopy = tierCardanoAddresses.find((ca) => ca.tierId === tier.id);

    return (
      <div id="token-sale-invest-modal">
        <div className="modal-bg" onClick={toggle} />
        <div className="modal-wrapper">
          <div className="modal-header">
            <div className="column">
              <h2>{this.tierName(tier.order)}</h2>
            </div>
            <div className="exit-modal" onClick={toggle}>
              <i className="fa fa-times" />
            </div>
          </div>
          <div className="modal-content">
            <div className="invest-info">
              <div className="info-row">
                <div className="label">
                  {I18n.t('global.invest.from')}
                </div>
                <div className="info">
                  {displayDate(tier.start_date)} CET
                </div>
              </div>
              <div className="info-row">
                <div className="label">
                  {I18n.t('global.invest.to')}
                </div>
                <div className="info">
                  {displayDate(tier.end_date)} CET
                </div>
              </div>
              <div className="info-row">
                <div className="label">
                  {I18n.t('global.invest.aci_price')}
                </div>
                <div className="info">
                  {displayPrice(tier.aci_price)} EUR
                </div>
              </div>
              <div className="info-row">
                <div className="label">
                  {I18n.t('global.invest.min_investment')}
                </div>
                <div className="info">
                  {user.level === 'professor' && tier.order === 3 ? (
                    displaySpecialMinimumInvestmentMargins(100)
                  ) : (
                    displayMinimumInvestmentMargins(tier)
                  )}
                </div>
              </div>
              <div className="info-row">
                <div className="label">
                  {I18n.t('global.invest.max_investment')}
                </div>
                <div className="info">
                    {displayMaximumInvestmentMargins(tier)}
                </div>
              </div>
            </div>
            <div className="calculator-wrapper">
              <div className="invest-modal-note">
                {I18n.t('global.invest.currency_calculator')}
              </div>
              <Slider
                min={user.level === 'professor' && tier.order === 3 ? 100 : toInteger(tier.min_investment)}
                max={toInteger(tier.max_investment)}
                defaultValue={this.state.eurValue}
                value={this.state.eurValue}
                onChange={(value) => this.handleSliderChange(value)}
              />
              <div className="input-fields-wrapper">
                <div className="input-wrapper">
                  <input type="number" name="adaValue" onChange={this.handleChange} value={adaValue} />
                  <div className="currency">
                    ADA
                  </div>
                </div>
                <div className="divider">
                  =
                  <div className="conversion-info">
                    1 ADA = {displayPrice(this.props.oneAdaToEuro)} EUR
                  </div>
                </div>
                <div className="input-wrapper">
                  <input type="number" name="eurValue" onChange={this.handleChange} value={eurValue} />
                  <div className="currency">
                    EUR
                  </div>
                  <div className="conversion-info">
                    {user.level === 'professor' && tier.order === 3 ? (
                      displaySpecialMinimumInvestmentMargins(100)
                    ) : (
                      displayMinimumInvestmentMargins(tier)
                    )}
                    &nbsp;-&nbsp;
                    {displayMaximumInvestmentMargins(tier)}
                  </div>
                </div>
                <div className="divider">
                  =
                  <div className="conversion-info">
                    1 ACI = {displayPrice(tier.aci_price)} EUR
                  </div>
                </div>
                <div className="input-wrapper">
                  <input type="number" name="aciValue" onChange={this.handleChange} value={aciValue} />
                  <div className="currency">
                    ACI
                  </div>
                </div>
                <div className="divider mobile-only">
                  <i className="fa fa-arrow-down"></i>
                </div>
              </div>
            </div>

            <div className="invest-modal-note">
              {I18n.t('global.invest.modal_note')}
            </div>

            <div className="invest-address-link-wrapper">
              <input value={cardanoAddressForCopy.value} id="investAddress" readOnly />
              <button className="copy" onClick={this.copyToClipboard.bind(this)}>
                {I18n.t('global.referrals.referral.referral_copy_button')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
TokenSaleInvestModal.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  tier: PropTypes.object,
  oneAdaToEuro: PropTypes.number,
};
export default TokenSaleInvestModal;
