import React from 'react';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import toFinite from 'lodash/toFinite';
import toInteger from 'lodash/toInteger';
import { PieChart } from 'react-minimal-pie-chart';
import graphic from '../../assets/images/graphics/aci_hero_bg_graphic.png';
import SimplePoller from '../utils/simple_poller';
import { DEFAULT_TIER_POLL_TIME_MILISECONDS, displayLargeNumber, isOngoing, hasPassed } from '../utils';
import CountdownTimer from './CountdownTimer';

const mq = window.matchMedia('(max-width: 550px)');

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tiers: props.tiers,
      mobileView: mq.matches,
      transactions: props.transactions,
      time: new Date(),
    };
    this.poller = new SimplePoller();
  }

  componentDidMount() {
    mq.addEventListener('change', this.checkScreenSize.bind(this));
    if (this.props.user) this.poller.startDefaultPoller(this.updateFromUserProfilePoll);
    this.poller.startPoll('/tiers/current', DEFAULT_TIER_POLL_TIME_MILISECONDS, this.updateTiers);
    this.intervalID = setInterval(
      () => this.tick(),
      1000,
    );
  }

  componentWillUnmount() {
    mq.removeEventListener('change', this.checkScreenSize);
    this.poller.clearAll();
    clearInterval(this.intervalID);
  }

  calcPercentage(total, val) {
    return ((100 * val) / total).toFixed(2);
  }

  checkScreenSize() {
    this.setState({
      mobileView: mq.matches,
    });
  }

  updateTiers = (tiers) => {
    this.setState({ tiers });
  };

  updateFromUserProfilePoll = ({ transactions, tiers }) => {
    this.setState({ transactions, tiers });
  };

  tick() {
    this.setState({
      time: new Date(),
    });
  }

  tierName = (order) => {
    switch (order) {
      case 2:
        return I18n.t('global.token_sale.invest.early_sale_t1');
      case 3:
        return I18n.t('global.token_sale.invest.early_sale_t2');
      case 4:
        return I18n.t('global.token_sale.invest.community_sale');
      default:
        return '';
    }
  };

  render () {
    const { tiers, mobileView, transactions, time} = this.state;
    const totalAllotedACITokens = sumBy(tiers, (t) => toFinite(t.total_tokens_allotted));

    return (
      <div className="token-sale-body">
        <div className="bg-graphic-wrapper">
          <img src={graphic} alt="Blockademia Graphic" />
        </div>
        <div className="bg-graphic-wrapper alt">
          <img src={graphic} alt="Blockademia Graphic" />
        </div>
        <div className="token-sale-box dashboard-view">
          <div className="dashboard-area">
            {hasPassed(tiers[3], time) ? (
              <div className="token-sale-finished-message">
                <h3>{I18n.t('global.dashboard.sale_end.text')}</h3>
              </div>
            ) : (
              <>
                {tiers.map((tier) => (
                  <React.Fragment key={tier.id}>
                    {isOngoing(tier, time) && (
                      <div className="countdown-wrapper">
                        <h2>
                          {this.tierName(tier.order)} {I18n.t('global.dashboard.countdown.ends_in')}:
                        </h2>
                        <CountdownTimer value={tier.end_date} />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}

            {tiers && (
              <div className="graphs-wrapper">
                <div className="token-sale-distribution-wrapper">
                  <h2>{I18n.t('global.dashboard.sale_distribution')}</h2>
                  <div className="chart-wrapper">
                    <div className="chart-field">
                      {tiers.map((tier) => (
                        <div key={tier.id} className="chart-item">
                          <div className="bullet" />
                          <div className="item-data">
                            <h3>{tier.name}:</h3>
                            <span>{displayLargeNumber(tier.total_tokens_allotted)} ACI - {this.calcPercentage(totalAllotedACITokens, tier.order === 2 ? Number(tier.total_tokens_allotted) - 1000 : Number(tier.total_tokens_allotted))}%</span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="chart-field">
                      <PieChart
                        data={[
                          {
                            title: tiers[0].name,
                            value: Math.round(this.calcPercentage(totalAllotedACITokens, Number(tiers[0].total_tokens_allotted))),
                            color: 'url(#g0)',
                          },
                          {
                            title: tiers[1].name,
                            value: Math.round(this.calcPercentage(totalAllotedACITokens, Number(tiers[1].total_tokens_allotted))),
                            color: 'url(#g1)',
                          },
                          {
                            title: tiers[2].name,
                            value: Math.round(this.calcPercentage(totalAllotedACITokens, Number(tiers[2].total_tokens_allotted))),
                            color: 'url(#g2)',
                          },
                          {
                            title: tiers[3].name,
                            value: Math.round(this.calcPercentage(totalAllotedACITokens, Number(tiers[3].total_tokens_allotted))),
                            color: 'url(#g3)',
                          },
                        ]}
                        className="pie-chart"
                      >
                        <defs>
                          <linearGradient id="g0">
                            <stop offset="0%" stopColor="#9e1f63" />
                            <stop offset="100%" stopColor="#7b1c54" />
                          </linearGradient>
                          <linearGradient id="g1">
                            <stop offset="0%" stopColor="#b2196b" />
                            <stop offset="100%" stopColor="#a80745" />
                          </linearGradient>
                          <linearGradient id="g2">
                            <stop offset="0%" stopColor="#da1c5c" />
                            <stop offset="100%" stopColor="#c71f3c" />
                          </linearGradient>
                          <linearGradient id="g3">
                            <stop offset="0%" stopColor="#ff5733" />
                            <stop offset="100%" stopColor="#fb7356" />
                          </linearGradient>
                        </defs>
                      </PieChart>
                    </div>
                  </div>
                </div>
                <div className="token-release-schedule-wrapper">
                  <h2>{I18n.t('global.dashboard.sale_schedule')}</h2>
                  {!mobileView ? (
                    <div className="chart-wrapper">
                      <div className="chart-row">
                        <span>
                          {I18n.t('global.dashboard.schedule_table.distribution')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.aci_tokens')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.release_plan')}
                        </span>
                      </div>
                      <div className="chart-row">
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_1.cell_1')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_1.cell_2')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_1.cell_3')}
                        </span>
                      </div>
                      <div className="chart-row">
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_2.cell_1')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_2.cell_2')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_2.cell_3')}
                        </span>
                      </div>
                      <div className="chart-row">
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_3.cell_1')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_3.cell_2')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_3.cell_3')}
                        </span>
                      </div>
                      <div className="chart-row">
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_4.cell_1')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_4.cell_2')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_4.cell_3')}
                        </span>
                      </div>
                      <div className="chart-row">
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_5.cell_1')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_5.cell_2')}
                        </span>
                        <span>
                          {I18n.t('global.dashboard.schedule_table.column_5.cell_3')}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="chart-wrapper">
                      <div className="column">
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.distribution')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_1.cell_1')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.aci_tokens')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_1.cell_2')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.release_plan')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_1.cell_3')}
                          </span>
                        </div>
                      </div>
                      <div className="column">
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.distribution')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_2.cell_1')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.aci_tokens')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_2.cell_2')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.release_plan')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_2.cell_3')}
                          </span>
                        </div>
                      </div>
                      <div className="column">
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.distribution')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_3.cell_1')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.aci_tokens')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_3.cell_2')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.release_plan')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_3.cell_3')}
                          </span>
                        </div>
                      </div>
                      <div className="column">
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.distribution')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_4.cell_1')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.aci_tokens')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_4.cell_2')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.release_plan')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_4.cell_3')}
                          </span>
                        </div>
                      </div>
                      <div className="column">
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.distribution')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_5.cell_1')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.aci_tokens')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_5.cell_2')}
                          </span>
                        </div>
                        <div className="cell-content">
                          <span>
                            {I18n.t('global.dashboard.schedule_table.release_plan')}:
                          </span>
                          <span>
                            {I18n.t('global.dashboard.schedule_table.column_5.cell_3')}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  tiers: PropTypes.array,
  transactions: PropTypes.array,
};

export default Dashboard;
