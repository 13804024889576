import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import graphic from '../../assets/images/graphics/aci_hero_bg_graphic.png';
import TokenSaleApplyModal from './Invest/TokenSaleApplyModal';
import TokenSaleInvestModal from './Invest/TokenSaleInvestModal';
import TokenSaleRulesModal from './Invest/TokenSaleRulesModal';
import TransactionTable from './Invest/TransactionTable';
import {
  displayDate, displayPrice, isOngoing, displayLargeNumber,
  hasPassed, displayMaximumInvestmentMargins,
  displayMinimumInvestmentMargins, displaySpecialMinimumInvestmentMargins,
  DEFAULT_TIER_POLL_TIME_MILISECONDS,
} from '../utils';
import SimplePoller from '../utils/simple_poller';

class Invest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesModalOpen: false,
      applyModalOpen: false,
      investModalOpen: false,
      time: new Date(),
      transactions: props.transactions,
      tiers: props.tiers,
      oneAdaToEuro: props.oneAdaToEuro,
      orderState: null,
      refunds: props.refunds,
    };
    this.poller = new SimplePoller();
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000,
    );
    if (this.props.registeredUser) this.poller.startDefaultPoller(this.updateFromUserProfilePoll);
    this.poller.startPoll('/tiers/current', DEFAULT_TIER_POLL_TIME_MILISECONDS, this.updateTiers);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.poller.clearAll();
  }

  updateTransactions = (transactions) => {
    this.setState({ transactions });
  };

  updateTiers = (tiers) => {
    this.setState({ tiers });
  };

  // eslint-disable-next-line camelcase
  updateFromUserProfilePoll = ({ transactions, tiers, exchange_rate }) => {
    this.setState({
      // eslint-disable-next-line camelcase
      oneAdaToEuro: exchange_rate && exchange_rate.value,
      transactions,
      tiers,
    });
  };

  tick() {
    this.setState({
      time: new Date(),
    });
  }

  toggleRulesModal = () => {
    this.setState({ rulesModalOpen: !this.state.rulesModalOpen });
  };

  toggleApplyModal = () => {
    this.setState({ applyModalOpen: !this.state.applyModalOpen });
  };

  toggleInvestModal = (order) => {
    const { user } = this.props;
    if (!user.token_sale_access) return;
    this.setState({
      investModalOpen: !this.state.investModalOpen,
      orderState: order,
    });
  };

  tierName = (order) => {
    switch (order) {
      case 1:
        return I18n.t('global.token_sale.invest.private_sale');
      case 2:
        return I18n.t('global.token_sale.invest.early_sale_t1');
      case 3:
        return I18n.t('global.token_sale.invest.early_sale_t2');
      case 4:
        return I18n.t('global.token_sale.invest.community_sale');
      default:
        return '';
    }
  };

  render () {
    const {
      rulesModalOpen, applyModalOpen, investModalOpen, time,
      transactions, tiers, oneAdaToEuro, orderState, refunds,
    } = this.state;
    const { user, registeredUser } = this.props;
    const enLocale = locale === 'en';

    return (
      <div className="token-sale-body">
        <div className="bg-graphic-wrapper">
          <img src={graphic} alt="Blockademia Graphic" />
        </div>
        <div className="bg-graphic-wrapper alt">
          <img src={graphic} alt="Blockademia Graphic" />
        </div>
        <div className="token-sale-box">
          <div className="rules-wrapper">
            <div className="rules-notice">
              {locale === 'tr' ? (
                <>
                  {I18n.t('global.token_sale.invest.rules_notice')} <span className="modal-trigger" onClick={this.toggleRulesModal.bind(this)}>{I18n.t('global.token_sale.invest.rules_word')}</span> okuyun.
                </>
              ) : (
                <>
                  {I18n.t('global.token_sale.invest.rules_notice')} <span className="modal-trigger" onClick={this.toggleRulesModal.bind(this)}>{I18n.t('global.token_sale.invest.rules_word')}</span>.
                </>
              )}
              <TokenSaleRulesModal open={rulesModalOpen} toggle={this.toggleRulesModal.bind(this)} />
            </div>
          </div>
          <div className="sale-cards-wrapper">
            {orderBy(tiers, 'order').map((tier) => (
              <div className="sale-card" key={tier.order}>
                <div className="sale-card-number-icon">
                  {`0${tier.order}`}
                </div>
                <div className="sale-title">
                  {this.tierName(tier.order)}
                </div>
                <div className="sale-card-upper-info">
                  <div className="info-row">
                    <div className="label">
                      {I18n.t('global.token_sale.invest.from')}:
                    </div>
                    <div className="info">
                      {displayDate(tier.start_date)} CET
                    </div>
                  </div>
                  <div className="info-row">
                    <div className="label">
                      {I18n.t('global.token_sale.invest.to')}:
                    </div>
                    <div className="info">
                      {displayDate(tier.end_date)} CET
                    </div>
                  </div>
                </div>
                <div className="sale-card-bottom-info">
                  <div className="info-row">
                    <div className="label">
                      {I18n.t('global.token_sale.invest.aci_price')}:
                    </div>
                    <div className="info">
                      {displayPrice(tier.aci_price)} EUR
                    </div>
                  </div>
                  <div className="info-row">
                    <div className="label">
                      {I18n.t('global.token_sale.invest.min_investment')}:
                    </div>
                    <div className="info">
                      {registeredUser === true && user.level === 'professor' && tier.order === 3 ? (
                        displaySpecialMinimumInvestmentMargins(100)
                      ) : (
                        displayMinimumInvestmentMargins(tier)
                      )}
                    </div>
                  </div>
                  <div className="info-row">
                    <div className="label">
                      {I18n.t('global.token_sale.invest.max_investment')}:
                    </div>
                    <div className="info">
                      {displayMaximumInvestmentMargins(tier)}
                    </div>
                  </div>
                  <div className="info-row">
                    <div className="label">
                      {I18n.t('global.tokens_breakdown.total')}
                    </div>
                    <div className="info">
                      {displayLargeNumber(tier.total_tokens_allotted)},00 ACI
                    </div>
                  </div>
                  {false && tier.order !== 1 && (
                    <div className="info-row">
                      <div className="label">
                        {I18n.t('global.token_sale.invest.available')}:
                      </div>
                      <div className="info">
                        {displayLargeNumber(Math.round(tier.available_tokens))},00 ACI
                      </div>
                    </div>
                  )}
                </div>
                {tier.order === 1 && (
                  <div className="sale-card-btn disabled sold-out" onClick={this.toggleApplyModal.bind(this)}>
                    {I18n.t('global.login.misc.sold_out')}
                  </div>
                )}
                {tier.order === 2 && (
                  registeredUser === false ? (
                    <>
                      <a className={`sale-card-btn ${hasPassed(tier, time) ? 'disabled' : ''}`} href={enLocale ? '/users/sign_in' : `/${locale}/users/sign_in`}>{hasPassed(tier, time) ? I18n.t('global.login.misc.tier_ended') : I18n.t('global.login.buttons.login')}</a>
                      <div className="register-cta">
                        <span>{I18n.t('global.login.misc.register_cta')}</span>
                        <a className="register-link" href={enLocale ? '/users/sign_up' : `/${locale}/users/sign_up`}>{I18n.t('global.login.misc.register')}</a>
                      </div>
                    </>
                  ) : (
                    <div onClick={this.toggleInvestModal.bind(this, 2)} className={`sale-card-btn ${isOngoing(tier, time) && user.token_sale_access && tier.available_tokens > 0 ? '' : 'disabled'}`}>
                      {tier.available_tokens < 1 ? (
                        <>
                          {I18n.t('global.login.misc.sold_out')}
                        </>
                      ) : (
                        <>
                          {hasPassed(tier, time) ? I18n.t('global.login.misc.tier_ended') : I18n.t('global.token_sale.invest.invest')}
                        </>
                      )}
                    </div>
                  )
                )}
                {tier.order === 3 && (
                  registeredUser === false ? (
                    <>
                      <a className={`sale-card-btn ${hasPassed(tier, time) ? 'disabled' : ''}`} href={enLocale ? '/users/sign_in' : `/${locale}/users/sign_in`}>{hasPassed(tier, time) ? I18n.t('global.login.misc.tier_ended') : I18n.t('global.login.buttons.login')}</a>
                      <div className="register-cta">
                        <span>{I18n.t('global.login.misc.register_cta')}</span>
                        <a className="register-link" href={enLocale ? '/users/sign_up' : `/${locale}/users/sign_up`}>{I18n.t('global.login.misc.register')}</a>
                      </div>
                    </>
                  ) : (
                    <div onClick={this.toggleInvestModal.bind(this, 3)} className={`sale-card-btn ${isOngoing(tier, time) && user.token_sale_access && tier.available_tokens > 0 ? '' : 'disabled'}`}>
                      {tier.available_tokens < 1 ? (
                        <>
                          {I18n.t('global.login.misc.sold_out')}
                        </>
                      ) : (
                        <>
                          {hasPassed(tier, time) ? I18n.t('global.login.misc.tier_ended') : I18n.t('global.token_sale.invest.invest')}
                        </>
                      )}
                    </div>
                  )
                )}
                {tier.order === 4 && (
                  registeredUser === false ? (
                    <>
                      <a className={`sale-card-btn ${hasPassed(tier, time) ? 'disabled' : ''}`} href={enLocale ? '/users/sign_in' : `/${locale}/users/sign_in`}>{hasPassed(tier, time) ? I18n.t('global.login.misc.tier_ended') : I18n.t('global.login.buttons.login')}</a>
                      <div className="register-cta">
                        <span>{I18n.t('global.login.misc.register_cta')}</span>
                        <a className="register-link" href={enLocale ? '/users/sign_up' : `/${locale}/users/sign_up`}>{I18n.t('global.login.misc.register')}</a>
                      </div>
                    </>
                  ) : (
                    <div onClick={this.toggleInvestModal.bind(this, 4)} className={`sale-card-btn ${isOngoing(tier, time) && user.token_sale_access && tier.available_tokens > 0 ? '' : 'disabled'}`}>
                      {tier.available_tokens < 1 ? (
                        <>
                          {I18n.t('global.login.misc.sold_out')}
                        </>
                      ) : (
                        <>
                          {hasPassed(tier, time) ? I18n.t('global.login.misc.tier_ended') : I18n.t('global.token_sale.invest.invest')}
                        </>
                      )}
                    </div>
                  )
                )}
                <TokenSaleApplyModal open={applyModalOpen} toggle={this.toggleApplyModal.bind(this)} />
              </div>
            ))}
            {orderState !== null && (
              <TokenSaleInvestModal
                user={user}
                open={investModalOpen}
                toggle={this.toggleInvestModal.bind(this)}
                tier={tiers.find((t) => t.order === orderState)}
                oneAdaToEuro={oneAdaToEuro}
              />
            )}
          </div>
          {registeredUser === true && !user.token_sale_access && (
            <div className="sale-access-notice">
              {I18n.t('global.token_sale.invest.sale_access_notice')}
              {locale === 'en' ? (
                <Link to="/profile">
                  {I18n.t('global.token_sale.invest.sale_access_cta')}
                </Link>
              ) : (
                <Link to={`/${locale}/profile`}>
                  {I18n.t('global.token_sale.invest.sale_access_cta')}
                </Link>
              )}
            </div>
          )}
          {registeredUser === false && (
            <div className="login-notice">
              {I18n.t('global.token_sale.invest.login_notice')}
            </div>
          )}
        </div>

        {registeredUser === true && transactions.map((t) => (
          <TransactionTable
            key={t.tier_order}
            transactions={t.transactions}
            tier={find(tiers, { order: t.tier_order })}
            time={time}
            refunds={refunds}
          />
        ))}
      </div>
    );
  }
}

Invest.propTypes = {
  user: PropTypes.object,
  registeredUser: PropTypes.bool,
  tiers: PropTypes.array,
  transactions: PropTypes.array,
  refunds: PropTypes.array,
};

export default Invest;
