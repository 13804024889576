$(document).ready(function(){
  // Toggle admin mobile menu
  $('.admin-mobile-menu .toggle').on('click', function(event) {
    $('.admin-mobile-menu .sub-menu').toggleClass('active');
  });

  // Open admin filter
  $('.filter-btn-wrapper').on('click', function(event) {
    $('.filter-wrapper').addClass('show');
  });

  // Remove admin filter
  $('.close-filter-modal').on('click', function(event) {
    $('.filter-wrapper').removeClass('show');
  });

  $('.filter-wrapper').on('click', function(event) {
    const container = $(".filter-modal");
    if (!container.is(event.target) && container.has(event.target).length === 0) {
      $('.filter-wrapper').removeClass('show');
    }
  });

  $('.dropdown-toggle').on('click', function(event) {
    $('.dropdown-menu').toggle();
  });

  $('#user-transaction-tier-2').addClass('active');

  $('.tier-tabs-wrapper .tier-tab').on('click', function(event) {
    $('.tier-tabs-wrapper .tier-tab').removeClass('active');
    $(this).addClass('active');

    $('.user-transaction-table-wrapper').hide();

    if ($(this).hasClass('tier-5-trigger')) {
      $('#user-transaction-tier-5').css("display", "flex");
    } else if ($(this).hasClass('tier-4-trigger')) {
      $('#user-transaction-tier-4').css("display", "flex");
    } else if ($(this).hasClass('tier-3-trigger')) {
      $('#user-transaction-tier-3').css("display", "flex");
    } else {
      $('#user-transaction-tier-2').css("display", "flex");
    }
  });


  // Following code covers the selection of transactions and submitting for refund
  $(".bulk-select-checkbox").prop('checked', false);

  let transactionIDs = [];
  $(".bulk-select-checkbox").click(function(event) {
    transactionIDs = $(".user-transaction-table input:checkbox:checked").map(function() {
      return $(this).val();
    }).get();

    var selectedSubmissions = $('.bulk-select-checkbox:checked');
    if (selectedSubmissions.length > 0) {
      $('.individual-user-transactions-table-actions').css('display', 'block');
    } else {
      $('.individual-user-transactions-table-actions').css('display', 'none');
    }
  });

  $('.to-refund-transaction').on('click', function(event){
    event.preventDefault();
    path_template = "refunds/new?transactions_ids";
    firstItem = '%5B%5D=' + transactionIDs[0]
    arr = transactionIDs.map(i => '&transactions_ids%5B%5D=' + i);
    arr.shift()
    window.location.href = path_template + firstItem + arr;
  });
});