import React, { useRef, useEffect, useState } from "react";
import Tick from "@pqina/flip";

export const CountdownTimer = ({ value }) => {
  const divRef = useRef();
  const tickRef = useRef();

  const [tickValue, setTickValue] = useState(value);

  // Make the Tick instance and store it in the refs
  useEffect(() => {
    const didInit = tick => {
      tickRef.current = tick;
    };

    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
      value,
      didInit
    });
    return () => Tick.DOM.destroy(tickValue);
  }, [value]);

  // Start the Tick.down process
  useEffect(() => {
    const counter = Tick.count.down(value, {
      format: ["d", "h", "m", "s"]
    });

    // When the counter updates, update React's state value
    counter.onupdate = function(value) {
      setTickValue(value);
    };

    return () => {
      counter.timer.stop();
    };
  }, [value]);

  // When the tickValue is updated, update the Tick.DOM element
  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = {
        days: tickValue[0],
        hours: tickValue[1],
        minutes: tickValue[2],
        seconds: tickValue[3]
      };
    }
  }, [tickValue]);

  return (
    <div className="tick dash-tick">
      <div data-repeat="true" data-layout="horizontal fit">
        <div ref={divRef}>
          <div className="tick-group">
            <h4 data-key="days" data-transform="pad(00)" data-view="flip" />
            <h4 className="text">{I18n.t('global.dashboard.countdown.days')}</h4>
          </div>
          <div className="tick-group">
            <h4 data-key="hours" data-transform="pad(00)" data-view="flip" />
            <h4 className="text">{I18n.t('global.dashboard.countdown.hours')}</h4>
          </div>
          <div className="tick-group">
            <h4 data-key="minutes" data-transform="pad(00)" data-view="flip" />
            <h4 className="text">{I18n.t('global.dashboard.countdown.minutes')}</h4>
          </div>
          <div className="tick-group">
            <h4 data-key="seconds" data-transform="pad(00)" data-view="flip" />
            <h4 className="text">{I18n.t('global.dashboard.countdown.seconds')}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
