import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import graphic from '../../assets/images/graphics/aci_hero_bg_graphic.png';
import tokenSaleGraphic from '../../assets/images/graphics/aci_token_sale.png';

class TokenSale extends React.Component {
  render () {
    const { registeredUser } = this.props;
    const enLocale = locale === 'en';
    return (
      <div className="token-sale-body">
        <div className="bg-graphic-wrapper">
          <img src={graphic} alt="Blockademia Graphic" />
        </div>
        <div className="bg-graphic-wrapper alt">
          <img src={graphic} alt="Blockademia Graphic" />
        </div>
        <div className="token-sale-box">
          <div className="row space-between with-border">
            <h1>{I18n.t('global.token_sale.blockademia_word')} <span className="alt-color">{I18n.t('global.token_sale.on_word')}</span> {I18n.t('global.token_sale.cardano_word')}</h1>
            <div className="image-wrapper">
              <img src={tokenSaleGraphic} alt="Blockademia Token Sale graphic" />
            </div>
          </div>
          <div className="row space-between mt">
            <div className="column">
              <h3>{I18n.t('global.index.about.about_mission_tagline')}</h3>
              <span className="faded-text">{I18n.t('global.token_sale.join_sale_cta')}</span>
            </div>
              <div className="register-cta-wrapper">
                {registeredUser === false ? (
                  <>
                    <span className="faded-text">{I18n.t('global.token_sale.register_cta')}</span>
                    <a href={enLocale ? '/users/sign_up' : `/${locale}/users/sign_up`}>{I18n.t('global.token_sale.register')}</a>
                  </>
                ) : (
                  <Link to={enLocale ? '/invest' : `/${locale}/invest`}>{I18n.t('global.navigation.links.link_invest')}</Link>
                )}
              </div>
          </div>
        </div>
      </div>
    );
  }
}

TokenSale.propTypes = {
  registeredUser: PropTypes.bool,
};
export default TokenSale;