import React from "react";
import PropTypes from "prop-types";

class UpperReferralSection extends React.Component {
  copyToClipboard = () => {
    const value = document.getElementById('referralLink');
    value.select();
    document.execCommand('copy');
  };

  render () {
    const { user } = this.props;
    const baseURL =  window.location.origin;
    const referralToken = user.referral_token;
    const inputValue = baseURL + '/referrals?referral=' + referralToken;
    return (
      <div className="referral-section">
        <div className="section-title">
          {I18n.t('global.referrals.referral.referral_title')}
        </div>
        <div className="section-subtitle">
          {I18n.t('global.referrals.referral.referral_subtitle')}
        </div>
        <span className="description">
          {I18n.t('global.referrals.referral.referral_description')}
        </span>

        <div className="referral-link-wrapper">
          <input value={inputValue} id="referralLink" readOnly />
          <button className="copy" onClick={this.copyToClipboard.bind(this)}>
            {I18n.t('global.referrals.referral.referral_copy_button')}
          </button>
        </div>
      </div>
    );
  }
}

UpperReferralSection.propTypes = {
  user: PropTypes.object
};
export default UpperReferralSection;


