$(document).ready(function() {
  function updateGraphs(e) {
    var timePeriod = $(this).attr('id');
    $.ajax('/admins/dashboard', {
      method: 'GET',
      dataType: 'json',
      data: { time_period: timePeriod },
      beforeSend(xhr) {
        return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      }
    }).done(function(response) {
      Chartkick.charts["user-levels-chart"].updateData(response["user_levels"]);
      Chartkick.charts["verified-user-levels-chart"].updateData(response["verified_user_levels"]);

      Chartkick.charts["aci-per-level-chart"].updateData(response["aci_per_level"]);
      Chartkick.charts["ada-per-level-chart"].updateData(response["ada_per_level"]);
    });
  }

  if ($('.graph-area.admin')[0]) {
    var buttons = $('div.time-select-wrapper').find('button');
    $.each(buttons, function(index, button) {
      $(button).on('click', updateGraphs);
    });
  }

  $('.time-select-wrapper button').on('click', function(event) {
    $('.time-select-wrapper button').removeClass('active');
    $(this).addClass('active');
  });
});
