/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import camelcaseKeys from 'camelcase-keys-deep';
import UserInfoBox from './UserInfoBox';
import InvestCta from './InvestCta';
import ReferralProgram from './ReferralProgram';
import TokensBreakdown from './TokensBreakdown';
import SimplePoller from '../utils/simple_poller';
import { DEFAULT_TIER_POLL_TIME_MILISECONDS } from '../utils';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.poller = new SimplePoller();
    this.state = {
      userData: camelcaseKeys(props.user),
    };
  }

  componentDidMount() {
    this.poller.startPoll('/poll/user_profile', DEFAULT_TIER_POLL_TIME_MILISECONDS, this.updateUserData);
  }

  componentWillUnmount() {
    this.poller.clearAll();
  }

  updateUserData = ({ user_with_token_data }) => {
    this.setState({ userData: camelcaseKeys(user_with_token_data) });
    this.props.handleUpdateUser(user_with_token_data);
  };

  render () {
    const { user } = this.props;

    const {
      tokensForNextLevel, tokensNeededUntilNextLevel,
      iluCardanoAddress, aciGainedByTokenSale, aciGainedByReferrals,
      aciGainedByVouchers, aciGainedByInstantLevelUp, totalAciGained,
      tierCardanoAddresses,
    } = this.state.userData;

    return (
      <div className="user-profile-wrapper">
        <UserInfoBox user={user} />
        <div className="profile-row">
          <InvestCta user={this.state.userData} updateUserData={this.updateUserData} />
          <TokensBreakdown
            user={user}
            aciGainedByTokenSale={aciGainedByTokenSale}
            aciGainedByReferrals={aciGainedByReferrals}
            aciGainedByVouchers={aciGainedByVouchers}
            aciGainedByInstantLevelUp={aciGainedByInstantLevelUp}
            totalAciGained={totalAciGained}
          />
        </div>
        <ReferralProgram
          user={user}
          tokensForNextLevel={tokensForNextLevel}
          tokensNeededUntilNextLevel={tokensNeededUntilNextLevel}
          iluCardanoAddress={iluCardanoAddress}
          updateUserData={this.updateUserData}
        />
      </div>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object,
  handleUpdateUser: PropTypes.func,
};
export default Profile;
