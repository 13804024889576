import React from 'react';
import PropTypes from 'prop-types';
import UpperReferralSection from './UpperReferralSection';
import ReferralEffect from './ReferralEffect';
import ProgressSection from './ProgressSection';
import DelayNotice from './DelayNotice';
import LevelUpNotice from './LevelUpNotice';
import VoucherSection from './VoucherSection';

class ReferralProgram extends React.Component {
  constructor() {
    super();
    this.state = {
      time: new Date(),
    };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  tick() {
    this.setState({
      time: new Date(),
    });
  }

  render () {
    const {
      user, tokensForNextLevel, tokensNeededUntilNextLevel, iluCardanoAddress,
    } = this.props;
    const { time } = this.state;
    const isProfessor = user.level === 'professor';
    const levelUpUsed = user.instant_level_up_used === true;
    const levelUpDeadline = new Date('November 15, 2021 00:00:00');
    const deadlinePassed = time > levelUpDeadline;

    return (
      <section className="referral-program">
        <UpperReferralSection user={user} />
        <ReferralEffect />
        <ProgressSection
          user={user}
          tokensForNextLevel={tokensForNextLevel}
          tokensNeededUntilNextLevel={tokensNeededUntilNextLevel}
          iluCardanoAddress={iluCardanoAddress}
          deadlinePassed={deadlinePassed}
        />
        {deadlinePassed === false && !levelUpUsed && !isProfessor && (
          <>
            <DelayNotice />
            <LevelUpNotice />
          </>
        )}
        <VoucherSection updateUserData={this.props.updateUserData} />
      </section>
    );
  }
}

ReferralProgram.propTypes = {
  user: PropTypes.object,
  tokensForNextLevel: PropTypes.any,
  tokensNeededUntilNextLevel: PropTypes.number,
  iluCardanoAddress: PropTypes.string,
  updateUserData: PropTypes.func,
};

export default ReferralProgram;
