import React, { useState } from 'react';
import PropTypes from 'prop-types';

function CardanoAddressModal(props) {
  const [address, setAddress] = useState(props.user.returnAddress ? props.user.returnAddress : '');
  const [disabled, setDisabled] = useState(false);

  const { user, open, toggle } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (address && address.length > 0) {
      setDisabled(true);
      $.ajax('/update_return_address', {
        method: 'PUT',
        data: { 'user[return_address]': address },
      })
        .done(data => {
          if (data.error) {
            jQuery('body').append(`<div class="flash alert">${data.message}</div>`);
          } else {
            jQuery('body').append(`<div class="flash notice">${data.message}</div>`);
            toggle();
            jQuery.getJSON('/poll/user_profile', (userData) => {
              props.updateUserData(userData);
            });
          }
          setTimeout(() => {
            jQuery('.flash').fadeOut();
          }, 5000);
          jQuery('.flash').on('click', () => {
            jQuery('.flash').fadeOut();
          });
        })
        .always(() => {
          setDisabled(false);
        });
    }
  };

  return (
    <>
      {open && (
        <div id="cardano-address-modal">
          <div className="modal-bg" onClick={toggle} />
          <div className="modal-wrapper">
            <div className="modal-header">
              <div className="column">
                <h2>
                  {user.returnAddress ? (
                    <>
                      {I18n.t('global.referrals.profile.edit_cardano_address')}
                    </>
                  ) : (
                    <>
                      {I18n.t('global.referrals.profile.provide_cardano_address')}
                    </>
                  )}
                </h2>
                <div className="modal-subheader">
                  {I18n.t('global.referrals.profile.provide_cardano_address_modal_notice')}
                </div>
              </div>
              <div className="exit-modal" onClick={toggle}>
                <i className="fa fa-times" />
              </div>
            </div>
            <div className="modal-content">
              <div className="cardano-address-form-wrapper">
                <form action="/update_return_address" acceptCharset="UTF-8" method="post" onSubmit={handleSubmit}>
                  <input type="hidden" name="_method" value="put" />
                  <input
                    type="text"
                    name="user[return_address]"
                    id="user_return_address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <input type="submit" name="commit" value={I18n.t('global.referrals.profile.save')} className="save" disabled={disabled} />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
CardanoAddressModal.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  updateUserData: PropTypes.func,
};
export default CardanoAddressModal;
